var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-btn',{staticClass:"mb-4",attrs:{"type":"primary","size":"lg"},on:{"click":function($event){_vm.addNewReference = true}}},[_vm._v("Add Reference")]),_c('div',{staticClass:"text-image",attrs:{"id":"references"}},[_c('div',{staticClass:"row pb-5 pr-lg-5"},_vm._l((_vm.references),function(reference,index){return _c('div',{key:index,staticClass:"col-12 col-lg-6 image p-5"},[_c('div',{staticClass:"text-img",style:({
            'background-image': 'url(' + _vm.serverUrl + reference.imageUrl + ')',
          }),on:{"click":function($event){return _vm.updateReference(reference)}}},[_c('div',{staticClass:"text"},[_c('h1',[_vm._v(_vm._s(reference.title))]),_c('p',{domProps:{"innerHTML":_vm._s(reference.description)}})])])])}),0)]),_c('b-modal',{attrs:{"title":"Dodaj novi post","header-text-variant":"dark","header-bg-variant":"light","body-text-variant":"dark","body-bg-variant":"light","footer-text-variant":"dark","footer-bg-variant":"light"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('a')]},proxy:true}]),model:{value:(_vm.addNewReference),callback:function ($$v) {_vm.addNewReference=$$v},expression:"addNewReference"}},[_c('div',[_c('edit-reference-form',{attrs:{"isNew":true},on:{"update":() => {
            _vm.addNewReference = false;
            _vm.getData();
          }}})],1)]),_c('b-modal',{attrs:{"title":"Dodaj novi post","header-text-variant":"dark","header-bg-variant":"light","body-text-variant":"dark","body-bg-variant":"light","footer-text-variant":"dark","footer-bg-variant":"light"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('a')]},proxy:true}]),model:{value:(_vm.updateReferenceModal),callback:function ($$v) {_vm.updateReferenceModal=$$v},expression:"updateReferenceModal"}},[_c('div',[_c('edit-reference-form',{attrs:{"isNew":false,"model":_vm.selectedReference},on:{"update":() => {
            _vm.updateReferenceModal = false;
            _vm.getData();
          }}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }