<template>
  <b-card class="mb-4">
    <h5 slot="header" class="title">Edit Roles</h5>
    <div class="row" v-for="(role, index) in rolesLocal" :key="index">
      <div class="col-md-12 px-md-1">
        <div class="form-check">
          <label class="form-check-label">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="userLocal.roles"
              :value="role.name"
            />
            <span class="form-check-sign"></span>
            {{ role.name }}
          </label>
        </div>
      </div>
    </div>
    <b-button slot="footer" variant="success" fill @click="updateRoles"
      >Save</b-button
    >
    <b-button slot="footer" variant="secondary" fill @click="reset"
      >Reset</b-button
    >
  </b-card>
</template>
<script>
import UsersService from "@/services/UsersService.js";

export default {
  name: "EditRolesForm",
  data() {
    return {
      selectedRoles: [],
      userLocal: {},
      rolesLocal: [],
    };
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      },
    },
    roles: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    this.userLocal = JSON.parse(JSON.stringify(this.user));
    this.rolesLocal = JSON.parse(JSON.stringify(this.roles));
    this.$watch("user", () => {
      this.userLocal = JSON.parse(JSON.stringify(this.user));
    }),
      this.$watch("roles", () => {
        this.rolesLocal = JSON.parse(JSON.stringify(this.roles));
      });
  },
  methods: {
    hasRole(role) {
      if (this.userLocal.roles.includes(role)) return true;
      else return false;
    },
    async updateRoles() {
      for (var i = 0; i < this.userLocal.roles.length; i++) {
        var role = this.rolesLocal.find(
          (s) => s.name == this.userLocal.roles[i]
        );
        if (!role) this.userLocal.roles.splice(i, 1);
      }
      await UsersService.updateUserRoles({
        id: this.userLocal.id,
        roles: this.userLocal.roles,
      })
        .then((data) => {
          this.$notify({
            type: "success",
            text: data.msg,
          });
          this.$emit("update");
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            text: err.response.data.msg,
          });
        });
    },
    reset() {
      this.$emit("reset");
    },
  },
};
</script>
<style scoped>
.card {
  background-color: transparent !important;
  color: black;
}
</style>
