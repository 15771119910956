import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import AuthService from "../services/AuthService";

// VIEW IMPORTS
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/auth/Login.vue";
import Posts from "../views/Posts.vue";
import Post from "../views/Post.vue";
import Form from "../views/Form.vue";
import Reference from "../views/References.vue";
import NotFound from "../views/NotFound.vue";

import PanelLayout from "../views/admin/Dashboard.vue";
import PanelUsers from "../views/admin/Users.vue";
import PanelRoles from "../views/admin/Roles.vue";
import PanelPosts from "../views/admin/Posts.vue";
import PanelForms from "../views/admin/Forms.vue";
import PanelMessages from "../views/admin/Messages.vue";
import PanelPortfolio from "../views/admin/Portfolio.vue";
import PanelNotifications from "../views/admin/Notifications.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Zelena Energija | Solarni Paneli",
      description:
        "Otkrijte značaj solarnih panela, korake za njihovu ugradnju i kako doprinose očuvanju životne sredine.",
    },
  },
  {
    path: "/subvencije",
    name: "form",
    component: Form,
    meta: {
      title: "Zelena Energija | Subvencije",
      description:
        "Iskoristite priliku da podnesete prijavu za Sufinansiranje ugradnje Solarnih Panela i proverite dostupnost i mogućnosti.",
    },
  },
  {
    path: "/posts",
    name: "posts",
    component: Posts,
    meta: {
      title: "Zelena Energija | Novosti",
      description:
        "Najnovije novosti vezane za Solarne Panele i Subvencije vezane za iste.",
    },
  },
  {
    path: "/reference",
    name: "references",
    component: Reference,
    meta: {
      title: "Zelena Energija | Reference",
      description:
        "Pregledajte naše prethodno radjene projekte ugradnje Solarnih Panela.",
    },
  },
  {
    path: "/post/:id",
    name: "post",
    component: Post,
    meta: {
      title: "Zelena Energija | Novosti",
      description:
        "Najnovije novosti vezane za Solarne Panele i Subvencije vezane za iste.",
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/admin",
    name: "admin",
    component: PanelLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "notifications",
        name: "notifications",
        component: PanelNotifications,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "users",
        name: "users",
        component: PanelUsers,
        meta: {
          requiresAuth: true,
          requiredPermission: "admin.users",
        },
      },
      {
        path: "posts",
        name: "adminposts",
        component: PanelPosts,
        meta: {
          requiresPermission: "admin.posts",
          requiresAuth: true,
        },
      },
      {
        path: "forms",
        name: "forms",
        component: PanelForms,
        meta: {
          requiresPermission: "admin.forms",
          requiresAuth: true,
        },
      },
      {
        path: "messages",
        name: "messages",
        component: PanelMessages,
        meta: {
          requiresPermission: "admin.messages",
          requiresAuth: true,
        },
      },
      {
        path: "portfolio",
        name: "panelportfolio",
        component: PanelPortfolio,
        meta: {
          requiresPermission: "admin.portfolio",
          requiresAuth: true,
        },
      },
      {
        path: "roles",
        name: "roles",
        component: PanelRoles,
        meta: {
          requiresAuth: true,
          requiredPermission: "admin.roles",
        },
      },
    ],
  },
  {
    path: "*",
    name: "404",
    component: NotFound,
  },
];

function checkPermission(permissions, permission) {
  if (permissions.includes("*") || permissions.includes("*.*")) {
    return true;
  }
  if (permissions.includes(permission)) {
    return true;
  }
  if (permission.includes(".")) {
    let strings = permission.split(".");
    if (permissions.includes(strings[0] + ".*")) {
      return true;
    }
  }
  return false;
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { title, description } = to.meta;
  const defaultTitle = "Zelena Energija | Solarni Paneli";
  const defaultDescription =
    "Otkrijte značaj solarnih panela, korake za njihovu ugradnju i kako doprinose očuvanju životne sredine.";

  document.title = title || defaultTitle;

  const descriptionElement = document.querySelector(
    'head meta[name="description"]'
  );

  descriptionElement.setAttribute("content", description || defaultDescription);

  if (to.meta.requiresAuth) {
    if (!store.getters.isLoggedIn) {
      router.push("/login");
    } else {
      let data = await AuthService.userDetails().catch((err) => {
        store.dispatch("logout");
        if (err.response.status == 403) {
          router.push({
            path: "/login",
            query: {
              error_msg: err.response.data.msg,
            },
          });
        } else {
          router.push({
            path: "/login",
            query: {
              error_msg: "Logged out!",
            },
          });
        }
      });
      if (to.meta.requiresPermission) {
        console.log("Requires permission!");
        let permissions = data.permissions;
        console.log(permissions);
        if (checkPermission(permissions, to.meta.requiresPermission)) {
          console.log("Has permission");
          next();
        } else {
          console.log("No permission");
          router.push({
            path: "/",
            query: { msg: "You do not have permission to access that page!" },
          });
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});
export default router;
