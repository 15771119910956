<template>
  <div>
    <div class="row">
      <form
        v-on:submit.prevent="
          () => {
            addRole(newRoleText);
          }
        "
      >
        <b-form-input
          addon-left-icon="fas fa-plus"
          placeholder="Add Role"
          :class="{ 'has-success': success, 'has-danger': danger }"
          class="ml-3 mb-2"
          v-model="newRoleText"
        ></b-form-input>
      </form>
    </div>
    <div class="row">
      <div class="col-12">
        <vue-good-table :columns="columns" :rows="tableData" theme="polar-bear">
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <b-button
                variant="success"
                size="sm"
                style="margin-right: 5px"
                @click="
                  () => {
                    openModal(props.row.index, 'modify');
                  }
                "
                icon
              >
                <b-icon icon="gear"></b-icon>
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                @click="
                  () => {
                    openModal(props.row.index, 'delete');
                  }
                "
                icon
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <b-modal
      v-model="manageRoleModal"
      :title="'Modify ' + selectedRole.name"
      header-text-variant="dark"
      header-bg-variant="light"
      body-text-variant="dark"
      body-bg-variant="light"
      footer-text-variant="dark"
      footer-bg-variant="light"
    >
      <div>
        <div class="row">
          <div class="col-12">
            <div
              class="row"
              v-for="(permGroup, index) in permissions"
              :key="index"
            >
              <b-card
                :title="permGroup.title"
                style="width: 100%"
                bg-variant="light"
              >
                <div class="col-md-12 px-md-1">
                  <b-form-checkbox
                    v-for="(perm, index2) in permGroup.permissions"
                    :key="index2"
                    v-model="selectedRole.permissions"
                    :value="perm.permission"
                  >
                    {{ perm.name }}
                  </b-form-checkbox>
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="manageRoleModal = false"
          >Close</b-button
        >
        <b-button variant="success" @click="updatePermissions"
          >Save changes</b-button
        >
      </template>
    </b-modal>
    <b-modal
      v-model="deleteRoleModal"
      :title="'Delete ' + selectedRole.name"
      header-text-variant="dark"
      header-bg-variant="light"
      body-text-variant="dark"
      body-bg-variant="light"
      footer-text-variant="dark"
      footer-bg-variant="light"
    >
      <div>
        Are you sure you want to delete Role <b>{{ selectedRole.name }}</b
        >?
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="deleteRoleModal = false"
          >Cancel</b-button
        >
        <b-button
          variant="danger"
          @click="
            () => {
              removeRole(selectedRole.name);
            }
          "
          >Delete</b-button
        >
      </template>
    </b-modal>
  </div>
</template>
<script>
import RolesService from "@/services/RolesService.js";

export default {
  name: "RolesView",
  mounted() {},
  data() {
    return {
      columns: [
        { label: "#", field: "id" },
        { label: "Role Name", field: "name" },
        { label: "Permissions", field: "permissions" },
        { label: "Actions", field: "actions" },
      ],
      tableData: [],
      roles: [],
      permissions: [],
      manageRoleModal: false,
      deleteRoleModal: false,
      success: false,
      danger: false,
      inputText: "",
      newRoleText: "",
      selectedRole: { id: 0, name: "Not Selected", permissions: [] },
      dataTable: null,
    };
  },
  components: {},
  async created() {
    this.getData();
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    openModal(id, action) {
      console.log(id);
      if (action == "modify") {
        this.selectedRole = this.roles[id];
        this.manageRoleModal = !this.manageRoleModal;
      } else {
        this.selectedRole = this.roles[id];
        this.deleteRoleModal = !this.deleteRoleModal;
      }
    },
    async addRole(role) {
      let data = await RolesService.addRole({
        name: role,
        permissions: [],
      }).catch((err) => {
        console.log(err.response.data.msg);
        this.$notify({
          type: "error",
          text: err.response.data.msg,
        });
      });
      if (data) {
        this.$notify({ type: "success", text: data.msg });
        this.getData();
        this.newRoleText = "";
      }
    },
    async removeRole(role) {
      this.deleteRoleModal = false;
      let data = await RolesService.removeRole({
        name: role,
      }).catch((err) => {
        console.log(err.response.data.msg);
        this.$notify({
          type: "error",
          text: err.response.data.msg,
        });
      });
      if (data) {
        this.$notify({ type: "success", text: data.msg });
        this.getData();
      }
    },
    async updatePermissions() {
      let data = await RolesService.updatePermissions({
        name: this.selectedRole.name,
        permissions: this.selectedRole.permissions,
      }).catch((err) => {
        console.log(err.response.data.msg);
        this.$notify({
          type: "error",
          text: err.response.data.msg,
        });
      });
      console.log(data);
      if (data) {
        this.$notify({ type: "success", text: data.msg });
        this.getData();
      }
    },
    async removePermission(permission) {
      this.selectedRole.permissions.splice(
        this.selectedRole.permissions.findIndex((s) => s == permission),
        1
      );
    },
    async addPermission(permission, role) {
      let data = await RolesService.addPermission({
        name: role,
        permission: permission,
      }).catch((err) => {
        console.log(err.response.data.msg);
        this.$notify({
          type: "error",
          text: err.response.data.msg,
        });
      });
      if (data) {
        this.$notify({ type: "success", text: data.msg });
        this.getData();
        this.inputText = "";
      }
    },
    async getData() {
      let data = await RolesService.getRoles().catch((err) => {
        console.log(err);
        this.$notify({
          type: "error",
          text: "You do not have permissions to view roles!",
        });
        data = [];
      });
      this.roles = data.roles;
      this.permissions = data.permissions;
      console.log(data.permissions);
      this.tableData = [];
      for (var i = 0; i < data.roles.length; i++) {
        var role = data.roles[i];
        if (role.id == this.selectedRole.id) {
          this.selectedRole = role;
        }
        this.tableData.push({
          id: role.id,
          index: i,
          name: role.name,
          permissions: role.permissions.join(", "),
        });
      }
    },
  },
};
</script>
<style>
.has-success:after,
.has-danger:after {
  font-family: "nucleo";
  content: "\EA1B";
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 10px;
  color: #00f2c3;
  font-size: 11px;
}
</style>
