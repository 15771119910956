<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="tableData"
      theme="polar-bear"
      :pagination-options="{
        enabled: true,
        perPage: 5,
        position: 'bottom',
        perPageDropdown: [3, 7, 9],
        dropdownAllowAll: false,
        jumpFirstOrLast: true,
        firstLabel: 'First',
        lastLabel: 'Last',
        nextLabel: 'Next',
        prevLabel: 'Prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      }"
      :search-options="{ enabled: true }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'">
          <b-button
            variant="success"
            size="sm"
            @click="
              () => {
                openModal(props.row.id, 'view');
              }
            "
          >
            VIEW
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="
              () => {
                openModal(props.row.id, 'delete');
              }
            "
            icon
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
        </span>
        <span v-else-if="props.column.field == 'email'">
          <a :href="'mailto:' + props.formattedRow[props.column.field]">{{
            props.formattedRow[props.column.field]
          }}</a>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template> </vue-good-table
    ><b-modal
      v-model="deleteFormModal"
      :title="'Delete ' + selectedForm.email"
      header-text-variant="dark"
      header-bg-variant="light"
      body-text-variant="dark"
      body-bg-variant="light"
      footer-text-variant="dark"
      footer-bg-variant="light"
    >
      <div>
        Are you sure you want to delete submission from
        <b>{{ selectedForm.email }}</b
        >?
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="deleteFormModal = false"
          >Cancel</b-button
        >
        <b-button
          variant="danger"
          @click="
            () => {
              removeUser(selectedForm.id);
            }
          "
          >Delete</b-button
        >
      </template> </b-modal
    ><b-modal
      v-model="viewFormModal"
      :title="'View ' + selectedForm.email"
      header-text-variant="dark"
      header-bg-variant="light"
      body-text-variant="dark"
      body-bg-variant="light"
      footer-text-variant="dark"
      footer-bg-variant="light"
    >
      <div>
        <div class="row">
          <div class="col pr-2">
            <p class="view-data">
              <a class="view-data-label">Ime i Prezime:</a>
              <br />
              <a>{{ selectedForm.name }}</a>
            </p>
          </div>
          <div class="col pl-2">
            <p class="view-data">
              <a class="view-data-label">Telefon:</a>
              <br />
              <a>{{ selectedForm.phone }}</a>
            </p>
          </div>
        </div>
        <p class="view-data">
          <a class="view-data-label">Email:</a>
          <br />
          <a :href="'mailto:' + selectedForm.email">{{ selectedForm.email }}</a>
        </p>
        <div class="row">
          <div class="col pr-2">
            <p class="view-data">
              <a class="view-data-label">Grad:</a>
              <br />
              <a>{{ selectedForm.city }}</a>
            </p>
          </div>
          <div class="col pl-2">
            <p class="view-data">
              <a class="view-data-label">Adresa:</a>
              <br />
              <a>{{ selectedForm.address }}</a>
            </p>
          </div>
        </div>
        <p class="view-data">
          <a class="view-data-label">Fizičko/Pravno lice:</a>
          <br />
          <a>{{
            selectedForm.personType == 1 ? "Fizičko Lice" : "Pravno Lice"
          }}</a>
        </p>
        <p class="view-data">
          <a class="view-data-label">Tip Kuće:</a>
          <br />
          <a>{{ selectedForm.houseType == 1 ? "Prizemna" : "Spratna" }}</a>
        </p>
        <div class="row">
          <div class="col pr-2">
            <p class="view-data">
              <a class="view-data-label">Tip Krova:</a>
              <br />
              <a>{{ selectedForm.roofType }}</a>
            </p>
          </div>
          <div class="col pl-2">
            <p class="view-data">
              <a class="view-data-label">Tip Struje:</a>
              <br />
              <a>{{
                selectedForm.powerType == 1
                  ? "Monofazna"
                  : selectedForm.powerType == 2
                  ? "Trofazna"
                  : "Off-Grid"
              }}</a>
            </p>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <b-button variant="success" @click="viewFormModal = false"
          >Close</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import SubmissionsService from "@/services/SubmissionsService";
export default {
  name: "FormsView",
  components: {},
  data() {
    let expandedRows = [];
    return {
      dataTable: null,
      tableData: [],
      columns: [
        { label: "#", field: "id" },
        { label: "Name", field: "name" },
        { label: "Email", field: "email" },
        { label: "Phone", field: "phone" },
        { label: "City", field: "city" },
        { label: "Actions", field: "actions" },
      ],
      roles: [],
      viewFormModal: false,
      deleteFormModal: false,
      selectedForm: {},
      expandedRows,
    };
  },
  async mounted() {
    this.getData();
  },
  methods: {
    toggleRow(props) {
      var id = this.expandedRows.findIndex((el) => el == props.row.id);
      if (id == -1) {
        this.expandedRows = [props.row.id];
      } else {
        this.expandedRows = [];
      }
    },
    openModal(id, action) {
      let user = this.tableData.find((a) => a.id == id);
      if (action == "view") {
        this.selectedForm = user;
        this.viewFormModal = !this.viewFormModal;
      } else {
        this.selectedForm = user;
        this.deleteFormModal = !this.deleteFormModal;
      }
      console.log(this.selectedForm);
    },
    removeUser() {
      this.deleteFormModal = !this.deleteFormModal;
    },
    async getData() {
      this.$emit("loading");
      let data = await SubmissionsService.getForms().catch((err) => {
        console.log(err);
        this.$notify({
          title: "ERROR",
          type: "danger",
          text: "You do not have permissions to view forms!",
        });
        data = [];
      });
      let forms = data.forms;
      this.tableData = [];
      for (var i = 0; i < forms.length; i++) {
        let form = forms[i];
        this.tableData.push(form);
      }
    },
    checkPermission(permissions, permission) {
      if (permissions.includes("*") || permissions.includes("*.*")) {
        return true;
      }
      if (permissions.includes(permission)) {
        return true;
      }
      if (permission.includes(".")) {
        let strings = permission.split(".");
        if (permissions.includes(strings[0] + ".*")) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
<style type="scss">
.view-data {
  border: 1px solid green;
  padding: 20px;
  border-radius: 5px;
  .view-data-label {
    text-decoration: none !important;
    color: gray;
    font-size: 13px;
  }
  a {
    font-size: 16px;
  }
}
</style>
