<template>
  <div>
    <b-btn
      type="primary"
      class="mb-4"
      size="lg"
      :disabled="!permissions.hasAddPost"
      @click="addNewPost = true"
      >Add Post</b-btn
    >
    <post-card
      v-for="(data, index) in posts"
      :key="index"
      :postId="data.id"
      :isLoggedIn="isLoggedIn"
      :user="user"
      :permissions="permissions"
      manage
      comments
      @update="getData()"
    ></post-card>
    <b-modal
      v-model="addNewPost"
      title="Dodaj novi post"
      header-text-variant="dark"
      header-bg-variant="light"
      body-text-variant="dark"
      body-bg-variant="light"
      footer-text-variant="dark"
      footer-bg-variant="light"
    >
      <div>
        <edit-posts-form
          :isNew="true"
          @update="
            () => {
              addNewPost = false;
              getData();
            }
          "
        ></edit-posts-form>
      </div>
      <template #modal-footer><a></a></template>
    </b-modal>
  </div>
</template>
<script>
import PostsService from "@/services/PostsService";
import AuthService from "@/services/AuthService";
import EditPostsForm from "@/Forms/EditPostsForm";
import PostCard from "@/components/PostCard";
export default {
  name: "PanelPosts",
  data() {
    return {
      permissions: {
        userId: 0,
        hasRemoveAny: false,
        hasRemoveOwn: false,
        hasUpdateAny: false,
        hasUpdateOwn: false,
        hasAddPost: false,
      },
      posts: [],
      users: {},
      addNewPost: false,
      isLoggedIn: false,
      user: null,
    };
  },
  components: {
    EditPostsForm,
    PostCard,
  },
  async mounted() {
    this.isLoggedIn = !!this.$store.getters.isLoggedIn;
    let user = await AuthService.userDetails();
    this.user = user;
    this.permissions.userId = user.id;
    this.permissions.hasRemoveAny = this.checkPermission(
      user.permissions,
      "posts.removeany"
    );
    this.permissions.hasRemoveOwn = this.checkPermission(
      user.permissions,
      "posts.remove"
    );
    this.permissions.hasUpdateOwn = this.checkPermission(
      user.permissions,
      "posts.update"
    );
    this.permissions.hasUpdateAny = this.checkPermission(
      user.permissions,
      "posts.updateany"
    );
    this.permissions.hasAddPost = this.checkPermission(
      user.permissions,
      "posts.add"
    );
    this.getData();
  },
  methods: {
    removePost(id) {
      this.deleteRoleModal = !this.deleteRoleModal;
      PostsService.removePost({
        id: id,
      })
        .then((data) => {
          this.$notify({
            type: "success",
            text: data.msg,
          });
          this.getData();
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            text: err.response.data.msg,
          });
        });
    },
    async getData() {
      let data = await PostsService.getPosts().catch(() => {
        this.$notify({
          type: "danger",
          text: "You do not have permissions to view posts!",
        });
        data = [];
      });
      this.users = data.users;
      this.posts = [];
      for (var i = 0; i < data.posts.length; i++) {
        this.posts.push(data.posts[i]);
      }
    },
    checkPermission(permissions, permission) {
      if (permissions.includes("*") || permissions.includes("*.*")) {
        return true;
      }
      if (permissions.includes(permission)) {
        return true;
      }
      if (permission.includes(".")) {
        let strings = permission.split(".");
        if (permissions.includes(strings[0] + ".*")) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
<style>
.has-success:after,
.has-danger:after {
  font-family: "nucleo";
  content: "\EA1B";
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 10px;
  color: #00f2c3;
  font-size: 11px;
}
</style>
