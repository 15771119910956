<template>
  <div>
    <NavBar
      style="z-index: 1500; position: fixed; left: 0px; right: 0px"
      :color="scrollPosition > 20 ? 'white' : 'transparent'"
    ></NavBar>
    <div class="landing" id="home">
      <div class="landing-background">
        <div class="landing-background-color"></div>
      </div>
      <div class="landing-text row">
        <div class="landing-logo"></div>
        <div class="landing-header">Zelena Energija | Reference</div>
        <div class="landing-subheader">
          Iskoristite potencijal sunca na najbolji nacin!
        </div>
      </div>
    </div>
    <div class="text-image" id="references">
      <div class="p-5">
        <div class="tab-header">
          <h1 style="color: white">
            Pregledajte naše prethodne radove i projekte
          </h1>
          <div style="color: #8dd06c" class="line">_______</div>
          <h6 style="color: #8dd06c">Naši prethodni projekti:</h6>
        </div>
      </div>
      <div class="row pb-5">
        <div
          v-for="(reference, index) in references"
          :key="index"
          class="col-12 col-lg-6 image p-5"
        >
          <div
            class="text-img"
            @click="viewReference(reference)"
            :style="{
              'background-image': 'url(' + serverUrl + reference.imageUrl + ')',
            }"
          >
            <div class="text">
              <h1>{{ reference.title }}</h1>
              <p v-html="reference.description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="viewReferenceModal"
      :title="selectedReference.title"
      header-text-variant="dark"
      header-bg-variant="light"
      body-text-variant="dark"
      body-bg-variant="transparent"
      footer-text-variant="dark"
      footer-bg-variant="light"
      size="lg"
      style="margin-top: 150px"
    >
      <div style="text-align: center">
        <img
          style="max-width: 80vw; max-height: 80vh"
          :src="serverUrl + selectedReference.imageUrl"
        />
        <p>{{ selectedReference.description }}</p>
      </div>
      <template #modal-footer><a></a></template>
    </b-modal>
    <div class="footer">
      <b-container>
        <b-row>
          <b-col cols="12" md="6"
            ><iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2782.985990431689!2d19.10357317676359!3d45.771471012883836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475cb58a5533337f%3A0xf0589c48c5479961!2z0JzQsNGA0LjRmNC1INCR0YPRgNGB0LDRmyAyMywg0KHQvtC80LHQvtGA!5e0!3m2!1ssr!2srs!4v1719520736795!5m2!1ssr!2srs"
              width="100%"
              height="300"
              style="
                border: 0;
                border-radius: 10px;
                filter: invert(1) hue-rotate(196deg);
              "
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </b-col>
          <b-col cols="12" md="6" class="contactf">
            <h2>Kontakt</h2>
            <b-row>
              <b-col cols="12" style="margin-bottom: 10px">
                <a style="font-size: 20px" href="tel:+381600285701">
                  <b-icon
                    style="font-size: 25px; margin-top: -3px"
                    icon="phone"
                  ></b-icon
                  >+381 60 028 5701</a
                >
              </b-col>
              <b-col cols="12" style="margin-bottom: 10px">
                <a
                  style="font-size: 20px"
                  href="mailto:zzelenaenergija@gmail.com"
                >
                  <b-icon
                    style="font-size: 25px; margin-top: -3px"
                    icon="envelope-open"
                  ></b-icon>
                  zzelenaenergija@gmail.com</a
                >
              </b-col>
              <b-col cols="12" style="margin-bottom: 10px">
                <a
                  style="font-size: 20px"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/zzelenaenergijars"
                >
                  <b-icon
                    style="font-size: 25px; margin-top: -3px"
                    icon="facebook"
                  ></b-icon>
                  Zelena Energija
                </a>
              </b-col>
              <b-col cols="12">
                <a
                  href="https://www.instagram.com/zelena_energija_srbija/"
                  style="font-size: 20px"
                  target="_blank"
                  rel="noreferrer"
                >
                  <b-icon
                    style="font-size: 25px; margin-top: -3px"
                    icon="instagram"
                  ></b-icon>
                  zelenaenergija</a
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col style="margin-top: 20px"
            ><h6>Copyright © Zelena Energija 2024</h6></b-col
          >
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import ReferencesService from "@/services/ReferencesService.js";
import config from "@/services/config.js";
export default {
  name: "ReferencesView",
  data() {
    return {
      scrollPosition: null,
      references: [],
      serverUrl: "",
      viewReferenceModal: false,
      selectedReference: {},
    };
  },
  components: {
    NavBar,
  },
  async beforeCreate() {},
  async created() {},
  async mounted() {
    this.serverUrl = config.serverUrl();
    ReferencesService.getReferences().then((data) => {
      this.references = data.references;
    });
    window.addEventListener("scroll", this.updateScroll);
    this.getData();
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    viewReference(reference) {
      this.selectedReference = reference;
      this.viewReferenceModal = true;
    },
  },
};
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.footer {
  background-color: rgb(20, 20, 20);
  height: auto;
  padding-top: 50px;
  padding-bottom: 10px;
  color: white;
  width: 100%;
  background-color: black;
  color: rgb(129, 139, 130);
  padding: 50px;
  z-index: 1000;
}
.footer {
  .contactf {
    * {
      text-align: left;
    }
    h6 {
      text-transform: uppercase;
      font-weight: bold;
      color: rgb(82, 82, 82);
    }
    a {
      color: rgb(167, 167, 167);
    }
  }
}
.posts {
  position: absolute;
  top: 90vh;
  left: 0px;
  right: 0px;
  background-color: white;
}
.posts-container {
  margin-bottom: 100px;
  margin-top: -150px;
}
.btn-home {
  position: absolute;
  top: 5px;
  left: 15px;
}
.btn-add {
  position: absolute;
  top: 5px;
  right: 15px;
}
.ticket {
  font-size: 0.875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    transform 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.has-success:after,
.has-danger:after {
  font-family: "nucleo";
  content: "\EA1B";
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 10px;
  color: #00f2c3;
  font-size: 11px;
}
.bg-primary {
  position: absolute !important;
}
.bg-rotate:after {
  content: "";
  position: absolute;
  top: 80%;
  left: 0;
  height: 100%;
  width: 150%;
  background: #1e1e2f !important;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  transform: rotate(-5deg);
}
.about {
}
.landing {
  height: 40vh;
  .landing-background,
  .landing-background-color {
    position: fixed;
    left: -1vw;
    top: -1vh;
    width: 102vw;
    height: 102vh;
    background-color: #115d33;
    background-image: linear-gradient(60deg, #115d33, #228b22);
    background-position: 100% 10%;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(4px);
  }
  .landing-background {
    background-image: url("@/assets/img/DJI_0313.JPG");
  }
  .landing-background-color {
    opacity: 0.7;
    top: 0;
  }
  .landing-text {
    color: white;
    position: absolute;
    top: 20vh;
    left: 15vw;
    width: calc(130px + 40vw);
    height: calc(40px + 8vw);
    padding: 20px;
    border-left: 2px solid white;
    text-wrap: nowrap;
    .landing-logo {
      background-image: url("@/assets/logo.png");
      background-position: 0% 0%;
      background-size: contain;
      background-repeat: no-repeat;
      height: calc(10px + 7vw);
      width: calc(10px + 7vw);
      position: absolute;
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222) hue-rotate(30deg);
    }
    .landing-header {
      margin-left: calc(20px + 7vw);
      margin-top: -10px;
      color: white;
      font-weight: bold;
      text-align: left;
      font-size: calc(4vw + 10px);
      filter: drop-shadow(2px 2px 5px #222);
    }
    .landing-subheader {
      font-size: calc(0.2vw + 10px);
      text-align: left;
      margin-left: calc(20px + 7vw);
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222);
    }
  }
}
.text-image {
  overflow: hidden !important;
  z-index: 1;
  margin-top: -51px;
  position: relative;
  padding-bottom: 50px;

  .text-img {
    background-size: cover;
    width: calc(100% + 30px);
    margin-left: -15px;
    height: 500px;
    padding: 0px;
    z-index: 100;
    box-shadow: inset 00px -140px 120px -10px black;
    transition: all 100ms ease-in-out;
    &:hover {
      cursor: pointer;
      box-shadow: inset 00px -140px 120px 50px black;
    }
  }
  .text {
    z-index: 100;
    position: absolute;
    bottom: 120px;
    text-align: left;
    padding: 40px;
    height: 100px;
    color: white;
    h1 {
      font-size: calc(12px + 1vw);
    }
    p {
      font-size: calc(10px + 0.2vw);
    }
  }
}
</style>
