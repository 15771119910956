<template>
  <div id="app">
    <div id="fb-root"></div>
    <div
      :class="{ loading: true, 'loading-active': !timePassedClass }"
      v-if="!timePassedDisplay"
    >
      <img width="350" src="@/assets/ZE_Loading.gif" />
    </div>
    <notifications></notifications>
    <router-view />
  </div>
</template>
<script
  async
  defer
  crossorigin="anonymous"
  src="https://connect.facebook.net/sr_RS/sdk.js#xfbml=1&version=v21.0&appId=1529434947933929"
></script>

<script>
// @ is an alias to /src

export default {
  components: {},
  data() {
    return {
      timePassedClass: false,
      timePassedDisplay: false,
    };
  },
  mounted() {
    console.log("LOADED!!!");

    setTimeout(() => {
      this.timePassedClass = true;
      setTimeout(() => {
        this.timePassedDisplay = true;
      }, 250);
    }, 1000);
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
@import "~bootstrap/scss/bootstrap.scss";
#app {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #228b2257;
}

::selection {
  color: white;
  background: #228b2257;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.loading {
  z-index: 100000;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  transition: all 200ms ease-in-out;
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(0px);
  img {
    position: absolute;
    top: calc(40vh - 175px);
    left: calc(50vw - 175px);
  }
  opacity: 0;
}
.loading-active {
  z-index: 100000;
  opacity: 1;
  background-color: rgba(0, 22, 2, 0.76);
  backdrop-filter: blur(10px);
}
</style>
