<template>
  <div class="home">
    <NavBar
      style="z-index: 1500; position: fixed; left: 0px; right: 0px"
      :color="scrollPosition > 20 ? 'white' : 'transparent'"
    ></NavBar>
    <div class="landing" id="home">
      <div class="landing-background">
        <div class="landing-background-color"></div>
      </div>
      <div class="login-container">
        <div class="tab-header mb-4">
          <h1 style="color: black">Prijavite se</h1>
          <div class="line">_______</div>
          <h6>Pristupite administratorskom delu web sajta:</h6>
        </div>
        <div class="row contact-form mx-auto justify-center">
          <div class="col mx-auto justify-center">
            <b-form-group class="mx-auto" label="Korisničko ime:">
              <b-form-input
                v-model="username"
                placeholder="Korisničko ime"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row contact-form mx-auto justify-center">
          <div class="col mx-auto justify-center">
            <b-form-group class="mx-auto" label="Lozinka:">
              <b-form-input
                :class="{ error: error != '' }"
                type="password"
                v-model="password"
                placeholder="Lozinka"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <b-form-checkbox v-model="remember_me" class="text-left ml-3 mb-3"
          >Zapamti prijavu</b-form-checkbox
        >
        <div class="row contact-form">
          <div class="col ml-3 mt-4">
            <b-button @click="login" variant="success">Pošalji!</b-button>
          </div>
        </div>
        <div v-if="error != ''" class="row contact-form error-msg">
          <div class="col text-center">
            <a class="">{{ error }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import AuthService from "@/services/AuthService.js";

export default {
  name: "LoginView",
  components: {
    NavBar,
  },
  data() {
    return {
      scrollPosition: null,
      username: "",
      password: "",
      remember_me: false,
      error: "",
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    login() {
      console.log("TEST");
      AuthService.login({ username: this.username, password: this.password })
        .then((data) => {
          try {
            const remember_me = true;
            var token = data.token;
            var user = data.user;
            this.$store.dispatch("login", { token, user, remember_me });
            this.$router.push("/");
          } catch (lerror) {
            this.error = lerror.response.data.msg;
          }
        })
        .catch((output) => {
          console.log(output.response.data.msg);
          this.error = output.response.data.msg;
        });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>
<style lang="scss">
* {
  scroll-behavior: smooth !important;
}
.contact-form {
  text-align: left;
}
.landing {
  height: 80vh;
  .landing-background,
  .landing-background-color {
    position: fixed;
    left: -1vw;
    top: -1vh;
    width: 102vw;
    height: 102vh;
    background-color: #115d33;
    background-image: linear-gradient(60deg, #115d33, #228b22);
    background-position: 100% 10%;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(4px);
  }
  .landing-background {
    background-image: url("@/assets/img/DJI_0313.JPG");
  }
  .landing-background-color {
    opacity: 0.7;
    top: 0;
  }
  .landing-text {
    color: white;
    position: absolute;
    top: 20vh;
    left: 20vw;
    width: calc(130px + 40vw);
    height: calc(40px + 8vw);
    padding: 20px;
    border-left: 2px solid white;
    text-wrap: nowrap;
    .landing-logo {
      background-image: url("@/assets/logo.png");
      background-position: 0% 0%;
      background-size: contain;
      background-repeat: no-repeat;
      height: calc(10px + 7vw);
      width: calc(10px + 7vw);
      position: absolute;
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222) hue-rotate(30deg);
    }
    .landing-header {
      margin-left: calc(20px + 7vw);
      margin-top: -10px;
      color: white;
      font-weight: bold;
      text-align: left;
      font-size: calc(4vw + 10px);
      filter: drop-shadow(2px 2px 5px #222);
    }
    .landing-subheader {
      font-size: calc(0.2vw + 10px);
      text-align: left;
      margin-left: calc(20px + 7vw);
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222);
    }
  }
}

.login-container {
  position: fixed;
  margin: auto;
  align-self: center;
  width: 450px;
  height: 550px;
  top: calc(50vh - 275px);
  left: calc(50vw - 225px);
  padding: 25px;
  background-color: white;
}

.tab-header {
  color: #228b22;
  text-align: center;
  padding-bottom: 30px;
}

.error-msg {
  border-bottom: 1px solid red;
  border-color: red;
  box-shadow: 0px 4px 20px -10px red;
  padding: 10px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 10px;
}
</style>
