<template>
  <div>
    <div class="row">
      <div class="col-12 pr-md-1">
        <!-- /* eslint-disable */ -->
        <b-input label="Title" placeholder="Title" v-model="model.title">
        </b-input>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <ckeditor
          :editor="editor"
          v-model="editorData"
          :config="editorConfig"
          tag-name="textarea"
        ></ckeditor>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <img width="400" v-if="Boolean(imaged)" :src="imaged" />
        <img width="400" v-else :src="serverUrl + model.imageUrl" />
        <b-form-file
          class="mt-2"
          v-model="image"
          :state="Boolean(image)"
          placeholder="Choose a image or drop it here..."
          @change="onFileChange($event)"
          drop-placeholder="Drop image here..."
        ></b-form-file>
        <div class="mt-3">Selected file: {{ image ? image.name : "" }}</div>
      </div>
    </div>
    <br />
    <b-btn
      class="float-right"
      v-if="!isNew"
      slot="footer"
      variant="danger"
      fill
      @click="
        () => {
          deleteReference();
        }
      "
      >Delete</b-btn
    >
    <b-btn
      class="float-right"
      slot="footer"
      variant="success"
      fill
      @click="
        () => {
          if (isNew) addReference();
          else updateReference();
        }
      "
      >Save</b-btn
    >
  </div>
</template>
<script>
import ReferencesService from "@/services/ReferencesService";
import config from "@/services/config";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  props: {
    model: {
      type: Object,
      default: () => {
        return {
          title: "",
          description: "",
          imageUrl: "",
        };
      },
    },
    isNew: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: "Long text of the Post",
      editorConfig: {},
      image: null,
      serverUrl: "",
      imaged: null,
    };
  },
  created() {
    this.serverUrl = config.serverUrl();
    if (!this.isNew) {
      this.editorData = this.model.description;
    }
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.imaged = URL.createObjectURL(files[0]);
    },
    async addReference() {
      const formData = new FormData();
      formData.append("file", this.image);
      ReferencesService.uploadImage(formData)
        .then((data) => {
          this.model.imageUrl = data.imageUrl;
          ReferencesService.addReference({
            title: this.model.title ? this.model.title : "",
            description: this.editorData ? this.editorData : "",
            imageUrl: this.model.imageUrl,
          })
            .then((data) => {
              this.$notify({
                type: "success",
                text: data.msg,
              });
              this.$emit("update");
            })
            .catch((err) => {
              this.$notify({
                type: "danger",
                text: err.response.data.msg,
              });
            });
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            text: err.response.data.msg,
          });
        });
    },
    updateReference() {
      if (this.model.imageUrl != null && this.image == null) {
        this.uploadRef();
      } else {
        const formData = new FormData();
        formData.append("file", this.image);
        ReferencesService.uploadImage(formData)
          .then((data) => {
            this.model.imageUrl = data.imageUrl;
            this.uploadRef();
          })
          .catch((err) => {
            this.$notify({
              type: "danger",
              text: err.response.data.msg,
            });
          });
      }
    },
    uploadRef() {
      ReferencesService.updateReference({
        id: this.model.id,
        title: this.model.title ? this.model.title : "",
        description: this.editorData ? this.editorData : "",
        imageUrl: this.model.imageUrl,
      })
        .then((data) => {
          this.$notify({
            type: "success",
            text: data.msg,
          });
          this.$emit("update");
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            text: err.response.data.msg,
          });
        });
    },
    deleteReference() {
      ReferencesService.removeReference({
        id: this.model.id,
      })
        .then((data) => {
          this.$notify({
            type: "success",
            text: data.msg,
          });
          this.$emit("update");
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            text: err.response.data.msg,
          });
        });
    },
  },
};
</script>
<style></style>
