import axios from "axios";
import cfg from "./config";
const url = cfg.serverUrl() + "/posts/";
export default {
  getPost(id) {
    return axios.get(url + id).then((response) => response.data);
  },
  getPosts() {
    return axios.get(url + "").then((response) => response.data);
  },
  addPost(data) {
    return axios.post(url + "addPost/", data).then((response) => response.data);
  },
  updatePost(data) {
    return axios
      .post(url + "updatePost/", data)
      .then((response) => response.data);
  },
  removePost(data) {
    return axios
      .post(url + "removePost/", data)
      .then((response) => response.data);
  },
};
