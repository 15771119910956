import axios from "axios";
import cfg from "./config";
const url = cfg.serverUrl() + "/auth/";
export default {
  login(credentials) {
    return axios
      .post(url + "login/", credentials)
      .then((response) => response.data);
  },
  userDetails() {
    return axios.get(url + "details/").then((response) => response.data);
  },
};
