import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
const getDefaultState = (logout = false) => {
  var token = JSON.parse(localStorage.getItem("token"));
  var user = JSON.parse(localStorage.getItem("user"));
  var name = JSON.parse(localStorage.getItem("name"));
  var remember_me = JSON.parse(localStorage.getItem("remember_me"));
  if (!remember_me) {
    token = "";
    user = {};
  } else {
    Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  if (logout) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("remember_me");
    token = "";
    user = {};
    name = "";
  }
  return {
    token: token,
    user: user,
    name: !name ? null : name,
  };
};
export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    isLoggedIn: (state) => {
      return state.token;
    },
    getUser: (state) => {
      return state.user;
    },
    getName: (state) => {
      return state.name;
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
      localStorage.setItem("token", JSON.stringify(token));
    },
    SET_USER: (state, user) => {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    SET_REMEMBER_ME: (state, val) => {
      localStorage.setItem("remember_me", JSON.stringify(val));
    },
    RESET: (state) => {
      Object.assign(state, getDefaultState());
    },
    LOGOUT: (state) => {
      Object.assign(state, getDefaultState(true));
    },
    SET_NAME: (state, name) => {
      state.name = name;
      localStorage.setItem("name", JSON.stringify(name));
    },
  },
  actions: {
    login: ({ commit }, { token, user, remember_me }) => {
      commit("SET_TOKEN", token);
      commit("SET_USER", user);
      if (remember_me) {
        commit("SET_REMEMBER_ME", remember_me);
      }
      // set auth header
      Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },
    logout: ({ commit }) => {
      commit("LOGOUT", "");
    },
    setName: ({ commit }, { name }) => {
      commit("SET_NAME", name);
    },
  },
});
