<template>
  <div>
    <div class="row">
      <div class="col-md-4 pr-md-1">
        <!-- /* eslint-disable */ -->
        <b-input label="Title" placeholder="Title" v-model="model.title">
        </b-input>
      </div>
      <div class="col-md-8 pl-md-1">
        <b-input
          label="Subtitle"
          placeholder="Subtitle"
          v-model="model.subtitle"
        >
        </b-input>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <ckeditor
          :editor="editor"
          v-model="editorData"
          :config="editorConfig"
          tag-name="textarea"
        ></ckeditor>
      </div>
    </div>
    <br />
    <b-btn
      class="float-right"
      slot="footer"
      variant="success"
      fill
      @click="
        () => {
          if (isNew) addPost();
          else updatePost();
        }
      "
      >Save</b-btn
    >
  </div>
</template>
<script>
import PostsService from "@/services/PostsService";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  props: {
    model: {
      type: Object,
      default: () => {
        return {
          title: "",
          subtitle: "",
          text: "",
        };
      },
    },
    isNew: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: "Long text of the Post",
      editorConfig: {},
    };
  },
  created() {
    if (!this.isNew) {
      this.editorData = this.model.text;
    }
  },
  methods: {
    addPost() {
      PostsService.addPost({
        title: this.model.title ? this.model.title : "",
        subtitle: this.model.subtitle ? this.model.subtitle : "",
        text: this.editorData ? this.editorData : "",
      })
        .then((data) => {
          this.$notify({
            type: "success",
            text: data.msg,
          });
          this.$emit("update");
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            text: err.response.data.msg,
          });
        });
    },
    updatePost() {
      PostsService.updatePost({
        id: this.model.id,
        title: this.model.title ? this.model.title : "",
        subtitle: this.model.subtitle ? this.model.subtitle : "",
        text: this.editorData ? this.editorData : "",
      })
        .then((data) => {
          this.$notify({
            type: "success",
            text: data.msg,
          });
          this.$emit("update");
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            text: err.response.data.msg,
          });
        });
    },
  },
};
</script>
<style></style>
