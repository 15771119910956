<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="tableData"
      theme="polar-bear"
      :pagination-options="{
        enabled: true,
        perPage: 5,
        position: 'bottom',
        perPageDropdown: [3, 7, 9],
        dropdownAllowAll: false,
        jumpFirstOrLast: true,
        firstLabel: 'First',
        lastLabel: 'Last',
        nextLabel: 'Next',
        prevLabel: 'Prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      }"
      :search-options="{ enabled: true }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'">
          <b-button
            variant="success"
            size="sm"
            @click="
              () => {
                openModal(props.row.id, 'view');
              }
            "
          >
            VIEW
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="
              () => {
                openModal(props.row.id, 'delete');
              }
            "
            icon
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
        </span>
        <span v-else-if="props.column.field == 'contact'">
          <a :href="'mailto:' + props.formattedRow[props.column.field]">{{
            props.formattedRow[props.column.field]
          }}</a>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template> </vue-good-table
    ><b-modal
      v-model="deleteMessageModal"
      :title="'Delete ' + selectedMessage.email"
      header-text-variant="dark"
      header-bg-variant="light"
      body-text-variant="dark"
      body-bg-variant="light"
      footer-text-variant="dark"
      footer-bg-variant="light"
    >
      <div>
        Are you sure you want to delete submission from
        <b>{{ selectedMessage.email }}</b
        >?
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="deleteMessageModal = false"
          >Cancel</b-button
        >
        <b-button
          variant="danger"
          @click="
            () => {
              removeUser(selectedMessage.id);
            }
          "
          >Delete</b-button
        >
      </template> </b-modal
    ><b-modal
      v-model="viewMessageModal"
      :title="'View ' + selectedMessage.email"
      header-text-variant="dark"
      header-bg-variant="light"
      body-text-variant="dark"
      body-bg-variant="light"
      footer-text-variant="dark"
      footer-bg-variant="light"
    >
      <div>
        <div class="row">
          <div class="col pr-2">
            <p class="view-data">
              <a class="view-data-label">Ime i Prezime:</a>
              <br />
              <a>{{ selectedMessage.name }}</a>
            </p>
          </div>
          <div class="col pl-2">
            <p class="view-data">
              <a class="view-data-label">Kontakt:</a>
              <br />
              <a :href="'mailto:' + selectedMessage.contact">{{
                selectedMessage.contact
              }}</a>
            </p>
          </div>
        </div>
        <p class="view-data">
          <a class="view-data-label">Poruka:</a>
          <br />
          <a>{{ selectedMessage.message }}</a>
        </p>
      </div>
      <template #modal-footer>
        <b-button variant="success" @click="viewMessageModal = false"
          >Close</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import SubmissionsService from "@/services/SubmissionsService";
export default {
  name: "MessagesView",
  components: {},
  data() {
    let expandedRows = [];
    return {
      dataTable: null,
      tableData: [],
      columns: [
        { label: "#", field: "id" },
        { label: "Name", field: "name" },
        { label: "Contact", field: "contact" },
        { label: "Received At", field: "createdAt" },
        { label: "Actions", field: "actions" },
      ],
      roles: [],
      viewMessageModal: false,
      deleteMessageModal: false,
      selectedMessage: {},
      expandedRows,
    };
  },
  async mounted() {
    this.getData();
  },
  methods: {
    toggleRow(props) {
      var id = this.expandedRows.findIndex((el) => el == props.row.id);
      if (id == -1) {
        this.expandedRows = [props.row.id];
      } else {
        this.expandedRows = [];
      }
    },
    openModal(id, action) {
      let user = this.tableData.find((a) => a.id == id);
      if (action == "view") {
        this.selectedMessage = user;
        this.viewMessageModal = !this.viewMessageModal;
      } else {
        this.selectedMessage = user;
        this.deleteMessageModal = !this.deleteMessageModal;
      }
      console.log(this.selectedMessage);
    },
    removeUser() {
      this.deleteMessageModal = !this.deleteMessageModal;
    },
    async getData() {
      this.$emit("loading");
      let data = await SubmissionsService.getMessages().catch((err) => {
        console.log(err);
        this.$notify({
          title: "ERROR",
          type: "danger",
          text: "You do not have permissions to view forms!",
        });
        data = [];
      });
      let messages = data.messages;
      this.tableData = [];
      for (var i = 0; i < messages.length; i++) {
        let message = messages[i];
        this.tableData.push(message);
      }
    },
    checkPermission(permissions, permission) {
      if (permissions.includes("*") || permissions.includes("*.*")) {
        return true;
      }
      if (permissions.includes(permission)) {
        return true;
      }
      if (permission.includes(".")) {
        let strings = permission.split(".");
        if (permissions.includes(strings[0] + ".*")) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
<style type="scss">
.view-data {
  border: 1px solid green;
  padding: 20px;
  border-radius: 5px;
  .view-data-label {
    text-decoration: none !important;
    color: gray;
    font-size: 13px;
  }
  a {
    font-size: 16px;
  }
}
</style>
