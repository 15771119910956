import axios from "axios";
import cfg from "./config";
const url = cfg.serverUrl() + "/roles/";
export default {
    getRoles() {
        return axios
            .get(url + "")
            .then(response => response.data);
    },
    addRole(data) {
        return axios
            .post(url + "add/", data)
            .then(response => response.data);
    },
    removeRole(data) {
        return axios
            .post(url + "remove/", data)
            .then(response => response.data);
    },
    addPermission(data) {
        return axios
            .post(url + "addPermission/", data)
            .then(response => response.data);
    },
    removePermission(data) {
        return axios
            .post(url + "removePermission/", data)
            .then(response => response.data);
    },
    updatePermissions(data) {
        return axios
            .post(url + "updatePermissions/", data)
            .then(response => response.data);
    },
};