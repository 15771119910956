import axios from "axios";
import cfg from "./config";
const url = cfg.serverUrl() + "/references/";
export default {
  getReferences() {
    return axios.get(url).then((response) => response.data);
  },
  getLatestReferences() {
    return axios.get(url + "latest").then((response) => response.data);
  },
  addReference(data) {
    return axios.post(url + "add/", data).then((response) => response.data);
  },
  uploadImage(data) {
    return axios.post(url + "upload/", data).then((response) => response.data);
  },
  updateReference(data) {
    return axios.post(url + "update/", data).then((response) => response.data);
  },
  removeReference(data) {
    return axios.post(url + "remove/", data).then((response) => response.data);
  },
};
