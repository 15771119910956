var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.tableData,"theme":"polar-bear","pagination-options":{
      enabled: true,
      perPage: 5,
      position: 'bottom',
      perPageDropdown: [3, 7, 9],
      dropdownAllowAll: false,
      jumpFirstOrLast: true,
      firstLabel: 'First',
      lastLabel: 'Last',
      nextLabel: 'Next',
      prevLabel: 'Prev',
      rowsPerPageLabel: 'Rows per page',
      ofLabel: 'of',
      pageLabel: 'page', // for 'pages' mode
      allLabel: 'All',
    },"search-options":{ enabled: true }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('b-button',{attrs:{"variant":"success","size":"sm"},on:{"click":() => {
              _vm.openModal(props.row.id, 'view');
            }}},[_vm._v(" VIEW ")]),_c('b-button',{attrs:{"variant":"danger","size":"sm","icon":""},on:{"click":() => {
              _vm.openModal(props.row.id, 'delete');
            }}},[_c('b-icon',{attrs:{"icon":"trash"}})],1)],1):(props.column.field == 'contact')?_c('span',[_c('a',{attrs:{"href":'mailto:' + props.formattedRow[props.column.field]}},[_vm._v(_vm._s(props.formattedRow[props.column.field]))])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('b-modal',{attrs:{"title":'Delete ' + _vm.selectedMessage.email,"header-text-variant":"dark","header-bg-variant":"light","body-text-variant":"dark","body-bg-variant":"light","footer-text-variant":"dark","footer-bg-variant":"light"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){_vm.deleteMessageModal = false}}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":() => {
            _vm.removeUser(_vm.selectedMessage.id);
          }}},[_vm._v("Delete")])]},proxy:true}]),model:{value:(_vm.deleteMessageModal),callback:function ($$v) {_vm.deleteMessageModal=$$v},expression:"deleteMessageModal"}},[_c('div',[_vm._v(" Are you sure you want to delete submission from "),_c('b',[_vm._v(_vm._s(_vm.selectedMessage.email))]),_vm._v("? ")])]),_c('b-modal',{attrs:{"title":'View ' + _vm.selectedMessage.email,"header-text-variant":"dark","header-bg-variant":"light","body-text-variant":"dark","body-bg-variant":"light","footer-text-variant":"dark","footer-bg-variant":"light"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){_vm.viewMessageModal = false}}},[_vm._v("Close")])]},proxy:true}]),model:{value:(_vm.viewMessageModal),callback:function ($$v) {_vm.viewMessageModal=$$v},expression:"viewMessageModal"}},[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pr-2"},[_c('p',{staticClass:"view-data"},[_c('a',{staticClass:"view-data-label"},[_vm._v("Ime i Prezime:")]),_c('br'),_c('a',[_vm._v(_vm._s(_vm.selectedMessage.name))])])]),_c('div',{staticClass:"col pl-2"},[_c('p',{staticClass:"view-data"},[_c('a',{staticClass:"view-data-label"},[_vm._v("Kontakt:")]),_c('br'),_c('a',{attrs:{"href":'mailto:' + _vm.selectedMessage.contact}},[_vm._v(_vm._s(_vm.selectedMessage.contact))])])])]),_c('p',{staticClass:"view-data"},[_c('a',{staticClass:"view-data-label"},[_vm._v("Poruka:")]),_c('br'),_c('a',[_vm._v(_vm._s(_vm.selectedMessage.message))])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }