import axios from "axios";
import cfg from "./config";
const url = cfg.serverUrl() + "/users/";
export default {
  getUsers() {
    return axios.get(url + "").then((response) => response.data);
  },
  addUser(data) {
    return axios.post(url + "add/", data).then((response) => response.data);
  },
  removeUser(data) {
    return axios.post(url + "remove/", data).then((response) => response.data);
  },
  updateUser(data) {
    return axios.post(url + "update/", data).then((response) => response.data);
  },
  updateUserRoles(data) {
    return axios.post(url + "roles/", data).then((response) => response.data);
  },
  changePassword(data) {
    return axios
      .post(url + "changepassword/", data)
      .then((response) => response.data);
  },
};
