<template>
  <div v-if="!!post">
    <b-card
      type="post"
      :class="{ 'post-card': true, 'post-card-sm': type != 'lg' }"
      @click="openPost()"
    >
      <div class="author">
        <a>
          <h4 class="title">
            <b-btn
              v-if="type == 'lg'"
              style="margin-top: -4px"
              variant="success"
              @click="$router.back()"
              >Nazad</b-btn
            >
            <a class="author-name">
              {{ users[post.userId].username }}
            </a>
            <a class="date">
              {{ post.createdAt }}
            </a>
          </h4>
        </a>
      </div>
      <p class="card-text"></p>

      <div class="card-content">
        <div class="tab-header mb-1">
          <h1 style="color: black; margin-bottom: -10px">{{ post.title }}</h1>
          <div class="line">_______</div>
          <h6>{{ post.subtitle }}</h6>
        </div>
        <div v-if="type == 'lg'" class="card-desc-wrap">
          <p class="card-description" v-html="post.text"></p>
        </div>
      </div>

      <div v-if="manage" class="post-actions">
        <b-btn
          variant="success"
          size="sm"
          style="margin-right: 5px"
          :disabled="!hasUpdatePermission"
          @click="
            () => {
              editPostModal = true;
            }
          "
          icon
        >
          <b-icon icon="gear"></b-icon>
        </b-btn>
        <b-btn
          variant="danger"
          size="sm"
          :disabled="!hasDeletePermission"
          @click="
            () => {
              deletePostModal = true;
            }
          "
          icon
        >
          <b-icon icon="trash"></b-icon>
        </b-btn>
      </div>
    </b-card>
    <b-modal v-model="deletePostModal">
      <template slot="header">
        <h5 class="modal-title">Delete {{ post.title }}</h5>
      </template>
      <div>
        Are you sure you want to delete Post <b>{{ post.title }}</b
        >?
      </div>
      <template slot="footer">
        <b-btn type="secondary" @click="deletePostModal = false">Cancel</b-btn>
        <b-btn type="danger" @click="removePost()">Delete</b-btn>
      </template>
    </b-modal>
    <b-modal v-model="editPostModal">
      <template slot="header">
        <h5 class="modal-title">Update {{ post.title }}</h5>
      </template>
      <div>
        <edit-posts-form
          :isNew="false"
          :model="post"
          @update="
            () => {
              editPostModal = false;
              getPost();
            }
          "
        ></edit-posts-form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import EditPostsForm from "@/Forms/EditPostsForm.vue";
import PostsService from "@/services/PostsService";

export default {
  props: {
    postId: {
      type: Number,
    },
    manage: {
      type: Boolean,
    },
    comments: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
    permissions: {
      type: Object,
    },
    type: {
      type: String,
      default: "lg",
    },
    adminPart: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      post: null,
      users: null,
      showModal: false,
      deletePostModal: false,
      editPostModal: false,
      hasUpdatePermission: false,
      hasDeletePermission: false,
      isLoggedIn: false,
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  components: {
    EditPostsForm,
  },
  async created() {
    console.log("Created POST");
    this.getPost();
    if (this.adminPart) {
      this.isLoggedIn = !!this.$store.getters.isLoggedIn;
    } else {
      this.isLoggedIn = false;
    }
  },
  mounted() {
    this.$watch("postId", () => {
      this.getPost();
    });
  },
  methods: {
    async getPost() {
      let data = await PostsService.getPost(this.postId);
      this.post = data.post;
      this.users = data.users;
      this.formatDate();
      if (this.isLoggedIn) this.setPermissions();
    },
    openPost() {
      if (this.type != "lg") this.$router.push("/post/" + this.post.id);
    },
    formatDate(nDate = null) {
      if (!nDate) {
        let date = new Date(Date.parse(this.post.createdAt));
        this.post.createdAt =
          date.getDate() +
          ". " +
          this.monthNames[date.getMonth()] +
          " " +
          date.getFullYear() +
          ".";
      } else {
        let date = new Date(Date.parse(nDate));
        let formated =
          date.getDate() +
          ". " +
          this.monthNames[date.getMonth()] +
          " " +
          date.getFullYear() +
          ".";
        return formated;
      }
    },
    setPermissions() {
      if (this.user.id == this.post.userId && this.permissions.hasRemoveOwn) {
        this.hasDeletePermission = true;
      }
      if (this.user.id != this.post.userId && this.permissions.hasRemoveAny) {
        this.hasDeletePermission = true;
      }
      if (this.user.id == this.post.userId && this.permissions.hasUpdateOwn) {
        this.hasUpdatePermission = true;
      }
      if (this.user.id != this.post.userId && this.permissions.hasUpdateAny) {
        this.hasUpdatePermission = true;
      }
    },
    openModal() {
      this.showModal = !this.showModal;
    },
    removePost() {
      this.deletePostModal = false;
      PostsService.removePost({
        id: this.post.id,
      })
        .then((data) => {
          this.$notify({
            type: "success",
            text: data.msg,
          });
          this.$emit("update");
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            text: err.response.data.msg,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.post-card {
  position: relative;
}

.post-card-sm {
  &:hover {
    cursor: pointer !important;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}

* {
  text-align: left !important;
}
.post-actions {
  z-index: 1000000;
  position: absolute;
  top: 55px;
  right: 5px;
}
.author-name {
  color: gray !important;
}
.date {
  font-size: 12px;
  margin-left: 2px;
}
.card-post > .card-body {
  padding: 0px !important;
}
.author {
  padding: 10px;
  border-top-right-radius: 0.2857rem;
  border-top-left-radius: 0.2857rem;
  cursor: pointer;
}
.title {
  font-weight: 100 !important;
  margin-bottom: 0px !important;
}
.author-avatar {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  margin-bottom: 0px !important;
  margin-right: 3px;
}
.card {
  background-color: rgba(255, 255, 255);
  backdrop-filter: grayscale(1) blur(2px);
}
.card-title {
  font-size: 40px;
  margin-bottom: -10px !important;
}
.card-title:hover {
  cursor: pointer;
}
.card-subtitle {
  font-size: 20px;
}
.card-description {
  padding: 10px;
  margin-bottom: 0px;
}
.card-desc-wrap {
  margin-top: 20px;
  position: relative;
  padding-bottom: 2px;
  padding-left: 2px;
}
.card-content {
  margin-left: 10px;
  margin-top: -15px;
  padding: 15px;
}
.card-comments {
  background-color: transparent;
}
.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0) !important;
  transform: translate(0, 0) !important;
}
</style>
