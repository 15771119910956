<template>
  <div class="landing">
    <NavBar
      style="z-index: 1500; position: fixed; left: 0px; right: 0px; top: -40px"
      :color="'transparent'"
    ></NavBar>
    <div class="landing-background">
      <div class="landing-background-color"></div>
    </div>
    <div class="row justify-content-center">
      <div class="col-10 col-sm-10 col-md-8 col-lg-6 col-xl-6"></div>
    </div>
    <div class="about row justify-content-center">
      <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
        <div>
          <post-card
            :postId="$route.params.id"
            :adminPart="false"
            type="lg"
          ></post-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PostCard from "@/components/PostCard.vue";
import NavBar from "@/components/NavBar.vue";
export default {
  name: "PostDisplay",
  data() {
    return {
      posts: [],
      users: [],
      isLoggedIn: false,
      user: null,
      permissions: {
        userId: 0,
        hasRemoveAny: false,
        hasRemoveOwn: false,
        hasUpdateAny: false,
        hasUpdateOwn: false,
        hasRemoveCommentAny: false,
        hasRemoveCommentOwn: false,
        hasRemoveReplyAny: false,
        hasRemoveReplyOwn: false,
        hasAddPost: false,
      },
    };
  },
  components: {
    PostCard,
    NavBar,
  },
  async beforeCreate() {},
  async created() {
    console.log("Created");
  },
  async mounted() {
    console.log("Mounted");
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.posts-container {
  background-color: rgb(255, 255, 255) !important;
}
.btn-home {
  position: absolute;
  top: 5px;
  left: 15px;
}
.btn-add {
  position: absolute;
  top: 5px;
  right: 15px;
}
.ticket {
  font-size: 0.875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    transform 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.has-success:after,
.has-danger:after {
  font-family: "nucleo";
  content: "\EA1B";
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 10px;
  color: #00f2c3;
  font-size: 11px;
}
.bg-primary {
  position: absolute !important;
}
.bg-rotate:after {
  content: "";
  position: absolute;
  top: 80%;
  left: 0;
  height: 100%;
  width: 150%;
  background: #1e1e2f !important;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  transform: rotate(-5deg);
}
.about {
  margin-top: 150px;
}
.landing {
  height: 80vh;
  overflow-x: hidden;
  .landing-background,
  .landing-background-color {
    position: fixed;
    left: -1vw;
    top: -1vh;
    width: 102vw;
    height: 102vh;
    background-color: #115d33;
    background-image: linear-gradient(60deg, #115d33, #228b22);
    background-position: 100% 10%;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(4px);
  }
  .landing-background {
    background-image: url("@/assets/img/DJI_0313.JPG");
  }
  .landing-background-color {
    opacity: 0.7;
    top: 0;
  }
  .landing-text {
    color: white;
    position: absolute;
    top: 20vh;
    left: 20vw;
    width: calc(130px + 40vw);
    height: calc(40px + 8vw);
    padding: 20px;
    border-left: 2px solid white;
    text-wrap: nowrap;
    .landing-logo {
      background-image: url("@/assets/logo.png");
      background-position: 0% 0%;
      background-size: contain;
      background-repeat: no-repeat;
      height: calc(10px + 7vw);
      width: calc(10px + 7vw);
      position: absolute;
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222) hue-rotate(30deg);
    }
    .landing-header {
      margin-left: calc(20px + 7vw);
      margin-top: -10px;
      color: white;
      font-weight: bold;
      text-align: left;
      font-size: calc(4vw + 10px);
      filter: drop-shadow(2px 2px 5px #222);
    }
    .landing-subheader {
      font-size: calc(0.2vw + 10px);
      text-align: left;
      margin-left: calc(20px + 7vw);
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222);
    }
  }
}
</style>
