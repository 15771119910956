import axios from "axios";
import cfg from "./config";
const url = cfg.serverUrl() + "/notif/";
export default {
    getNotifications() {
        return axios.get(url + "").then((response) => response.data);
    },
    setSeen() {
        return axios.post(url + "seen/").then((response) => response.data);
    },
};