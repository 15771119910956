import axios from "axios";
import cfg from "./config";
const url = cfg.serverUrl() + "/submissions/";
export default {
  getMessages() {
    return axios.get(url + "messages").then((response) => response.data);
  },
  getForms() {
    return axios.get(url + "forms").then((response) => response.data);
  },
  sendMessage(data) {
    return axios
      .post(url + "sendMessage/", data)
      .then((response) => response.data);
  },
  sendForm(data) {
    return axios
      .post(url + "sendForm/", data)
      .then((response) => response.data);
  },
};
