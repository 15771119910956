var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.tableData,"theme":"polar-bear","pagination-options":{
      enabled: true,
      perPage: 5,
      position: 'bottom',
      perPageDropdown: [3, 7, 9],
      dropdownAllowAll: false,
      jumpFirstOrLast: true,
      firstLabel: 'First',
      lastLabel: 'Last',
      nextLabel: 'Next',
      prevLabel: 'Prev',
      rowsPerPageLabel: 'Rows per page',
      ofLabel: 'of',
      pageLabel: 'page', // for 'pages' mode
      allLabel: 'All',
    },"search-options":{ enabled: true }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[(_vm.hasModifyPermission)?_c('b-button',{staticStyle:{"margin-right":"5px"},attrs:{"variant":"success","size":"sm","icon":""},on:{"click":() => {
              _vm.openModal(props.row.id, 'modify');
            }}},[_c('b-icon',{attrs:{"icon":"gear"}})],1):_vm._e(),(_vm.hasDeletePermission)?_c('b-button',{attrs:{"variant":"danger","size":"sm","icon":""},on:{"click":() => {
              _vm.openModal(props.row.id, 'delete');
            }}},[_c('b-icon',{attrs:{"icon":"trash"}})],1):_vm._e()],1):(props.column.field == 'roles')?_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field].join(", "))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('b-modal',{attrs:{"title":'Modify ' + _vm.selectedUser.email,"header-text-variant":"dark","header-bg-variant":"light","body-bg-variant":"light","footer-bg-variant":"light"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){_vm.manageRoleModal = false}}},[_vm._v("Close")])]},proxy:true}]),model:{value:(_vm.manageRoleModal),callback:function ($$v) {_vm.manageRoleModal=$$v},expression:"manageRoleModal"}},[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',[_c('edit-profile-form',{attrs:{"user":_vm.selectedUser},on:{"update":_vm.getData,"reset":_vm.resetDetails}}),(_vm.hasEditRolesPermission)?_c('edit-roles-form',{attrs:{"user":_vm.selectedUser,"roles":_vm.roles},on:{"update":_vm.getData,"reset":_vm.resetRoles}}):_vm._e()],1)])])])]),_c('b-modal',{attrs:{"title":'Delete ' + _vm.selectedUser.email,"header-text-variant":"dark","header-bg-variant":"light","body-text-variant":"dark","body-bg-variant":"light","footer-text-variant":"dark","footer-bg-variant":"light"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){_vm.deleteRoleModal = false}}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":() => {
            _vm.removeUser(_vm.selectedUser.id);
          }}},[_vm._v("Delete")])]},proxy:true}]),model:{value:(_vm.deleteRoleModal),callback:function ($$v) {_vm.deleteRoleModal=$$v},expression:"deleteRoleModal"}},[_c('div',[_vm._v(" Are you sure you want to delete User "),_c('b',[_vm._v(_vm._s(_vm.selectedUser.email))]),_vm._v("? ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }