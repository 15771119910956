<template>
  <div class="hello">
    <b-navbar
      toggleable="lg"
      :type="color == 'transparent' ? 'dark' : 'light'"
      :variant="color"
      :style="
        color == 'transparent'
          ? {
              paddingTop: 'calc(20px + 4vw)',
              paddingRight: 'calc(20px + 8vw)',
              paddingLeft: 'calc(20px + 8vw)',
              paddingBottom: '30px',
              transition: 'all 100ms',
            }
          : {
              paddingTop: '20px',
              paddingRight: '30px',
              paddingLeft: '30px',
              paddingBottom: '20px',
              transition: 'all 100ms',
            }
      "
    >
      <b-navbar-brand href="/"> Zelena Energija</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="/#home">Početna</b-nav-item>
          <b-nav-item href="/#services">Usluge</b-nav-item>
          <b-nav-item href="/#references">Reference</b-nav-item>
          <b-nav-item href="/#contact">Kontakt</b-nav-item>
          <b-nav-item href="/posts">Novosti</b-nav-item>
          <b-nav-item-dropdown right v-if="isLoggedIn">
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>{{ user.firstName }} {{ user.lastName }}</em>
            </template>
            <b-dropdown-item href="/admin">Pregled</b-dropdown-item>
            <b-dropdown-item @click="logout">Odjavi se</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-button variant="success" href="/subvencije">Subvencije</b-button>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    color: {
      type: String,
      default: () => "transparent",
    },
    user: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  methods: {
    logout() {
      this.$emit("logout");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
