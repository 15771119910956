var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return (() => {
          _vm.addRole(_vm.newRoleText);
        }).apply(null, arguments)}}},[_c('b-form-input',{staticClass:"ml-3 mb-2",class:{ 'has-success': _vm.success, 'has-danger': _vm.danger },attrs:{"addon-left-icon":"fas fa-plus","placeholder":"Add Role"},model:{value:(_vm.newRoleText),callback:function ($$v) {_vm.newRoleText=$$v},expression:"newRoleText"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.tableData,"theme":"polar-bear"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('b-button',{staticStyle:{"margin-right":"5px"},attrs:{"variant":"success","size":"sm","icon":""},on:{"click":() => {
                  _vm.openModal(props.row.index, 'modify');
                }}},[_c('b-icon',{attrs:{"icon":"gear"}})],1),_c('b-button',{attrs:{"variant":"danger","size":"sm","icon":""},on:{"click":() => {
                  _vm.openModal(props.row.index, 'delete');
                }}},[_c('b-icon',{attrs:{"icon":"trash"}})],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)]),_c('b-modal',{attrs:{"title":'Modify ' + _vm.selectedRole.name,"header-text-variant":"dark","header-bg-variant":"light","body-text-variant":"dark","body-bg-variant":"light","footer-text-variant":"dark","footer-bg-variant":"light"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){_vm.manageRoleModal = false}}},[_vm._v("Close")]),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.updatePermissions}},[_vm._v("Save changes")])]},proxy:true}]),model:{value:(_vm.manageRoleModal),callback:function ($$v) {_vm.manageRoleModal=$$v},expression:"manageRoleModal"}},[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},_vm._l((_vm.permissions),function(permGroup,index){return _c('div',{key:index,staticClass:"row"},[_c('b-card',{staticStyle:{"width":"100%"},attrs:{"title":permGroup.title,"bg-variant":"light"}},[_c('div',{staticClass:"col-md-12 px-md-1"},_vm._l((permGroup.permissions),function(perm,index2){return _c('b-form-checkbox',{key:index2,attrs:{"value":perm.permission},model:{value:(_vm.selectedRole.permissions),callback:function ($$v) {_vm.$set(_vm.selectedRole, "permissions", $$v)},expression:"selectedRole.permissions"}},[_vm._v(" "+_vm._s(perm.name)+" ")])}),1)])],1)}),0)])])]),_c('b-modal',{attrs:{"title":'Delete ' + _vm.selectedRole.name,"header-text-variant":"dark","header-bg-variant":"light","body-text-variant":"dark","body-bg-variant":"light","footer-text-variant":"dark","footer-bg-variant":"light"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){_vm.deleteRoleModal = false}}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":() => {
            _vm.removeRole(_vm.selectedRole.name);
          }}},[_vm._v("Delete")])]},proxy:true}]),model:{value:(_vm.deleteRoleModal),callback:function ($$v) {_vm.deleteRoleModal=$$v},expression:"deleteRoleModal"}},[_c('div',[_vm._v(" Are you sure you want to delete Role "),_c('b',[_vm._v(_vm._s(_vm.selectedRole.name))]),_vm._v("? ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }