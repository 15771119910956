<template>
  <div>
    <b-btn type="primary" class="mb-4" size="lg" @click="addNewReference = true"
      >Add Reference</b-btn
    >

    <div class="text-image" id="references">
      <div class="row pb-5 pr-lg-5">
        <div
          v-for="(reference, index) in references"
          :key="index"
          class="col-12 col-lg-6 image p-5"
        >
          <div
            class="text-img"
            @click="updateReference(reference)"
            :style="{
              'background-image': 'url(' + serverUrl + reference.imageUrl + ')',
            }"
          >
            <div class="text">
              <h1>{{ reference.title }}</h1>
              <p v-html="reference.description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="addNewReference"
      title="Dodaj novi post"
      header-text-variant="dark"
      header-bg-variant="light"
      body-text-variant="dark"
      body-bg-variant="light"
      footer-text-variant="dark"
      footer-bg-variant="light"
    >
      <div>
        <edit-reference-form
          :isNew="true"
          @update="
            () => {
              addNewReference = false;
              getData();
            }
          "
        ></edit-reference-form>
      </div>
      <template #modal-footer><a></a></template>
    </b-modal>
    <b-modal
      v-model="updateReferenceModal"
      title="Dodaj novi post"
      header-text-variant="dark"
      header-bg-variant="light"
      body-text-variant="dark"
      body-bg-variant="light"
      footer-text-variant="dark"
      footer-bg-variant="light"
    >
      <div>
        <edit-reference-form
          :isNew="false"
          :model="selectedReference"
          @update="
            () => {
              updateReferenceModal = false;
              getData();
            }
          "
        ></edit-reference-form>
      </div>
      <template #modal-footer><a></a></template>
    </b-modal>
  </div>
</template>
<script>
import EditReferenceForm from "@/Forms/EditReferenceForm";
import ReferencesService from "@/services/ReferencesService";
import config from "@/services/config";
export default {
  name: "PanelPortfolio",
  data() {
    return {
      addNewReference: false,
      updateReferenceModal: false,
      isLoggedIn: false,
      selectedReference: null,
      references: [],
      serverUrl: "",
    };
  },
  components: {
    EditReferenceForm,
  },
  async mounted() {
    this.isLoggedIn = !!this.$store.getters.isLoggedIn;
    this.serverUrl = config.serverUrl();
    this.getData();
  },
  methods: {
    removeReference(id) {
      this.deleteRoleModal = !this.deleteRoleModal;
      ReferencesService.removeReference({
        id: id,
      })
        .then((data) => {
          this.$notify({
            type: "success",
            text: data.msg,
          });
          this.getData();
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            text: err.response.data.msg,
          });
        });
    },
    updateReference(reference) {
      this.selectedReference = reference;
      this.updateReferenceModal = true;
    },
    async getData() {
      let data = await ReferencesService.getReferences().catch(() => {
        this.$notify({
          type: "danger",
          text: "You do not have permissions to view posts!",
        });
        data = [];
      });
      this.references = data.references;
    },
    checkPermission(permissions, permission) {
      if (permissions.includes("*") || permissions.includes("*.*")) {
        return true;
      }
      if (permissions.includes(permission)) {
        return true;
      }
      if (permission.includes(".")) {
        let strings = permission.split(".");
        if (permissions.includes(strings[0] + ".*")) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.has-success:after,
.has-danger:after {
  font-family: "nucleo";
  content: "\EA1B";
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 10px;
  color: #00f2c3;
  font-size: 11px;
}
.text-image {
  z-index: 1;
  margin-top: -51px;
  padding-top: 100px;
  position: relative;
  padding-bottom: 50px;
  .img-1 {
    background-image: url("@/assets/img/DJI_0140.jpg");
  }
  .img-2 {
    background-image: url("@/assets/img/DJI_0006.jpg");
  }

  .text-img {
    background-size: cover;
    width: calc(100% + 30px);
    margin-left: -15px;
    height: 500px;
    padding: 0px;
    z-index: 100;
    box-shadow: inset 00px -140px 120px -10px black;
    transition: all 100ms ease-in-out;
    &:hover {
      cursor: pointer;
      box-shadow: inset 00px -140px 120px 50px black;
    }
  }
  .text {
    z-index: 100;
    position: absolute;
    bottom: 120px;
    text-align: left;
    padding: 40px;
    height: 100px;
    color: white;
    h1 {
      font-size: calc(12px + 1vw);
    }
    p {
      font-size: calc(10px + 0.2vw);
    }
  }
}
</style>
