<template>
  <b-card class="mb-4">
    <h5 slot="header" class="title">Edit Profile</h5>
    <div class="row mb-3">
      <div class="col-6 pr-md-1">
        <label for="firstName">Name:</label>
        <b-form-input
          id="firstName"
          label="First Name"
          placeholder="First Name"
          v-model="userLocal.firstName"
        >
        </b-form-input>
      </div>
      <div class="col-6 pr-md-1">
        <label for="lastName">Name:</label>
        <b-input
          id="lastName"
          label="Last Name"
          placeholder="Last Name"
          v-model="userLocal.lastName"
        >
        </b-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label for="email">Email:</label>
        <b-input
          id="email"
          label="Email"
          v-model="userLocal.email"
          placeholder="Email"
        >
        </b-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12"></div>
    </div>
    <b-button slot="footer" variant="success" fill @click="updateUser"
      >Save</b-button
    >
    <b-button slot="footer" variant="secondary" fill @click="reset"
      >Reset</b-button
    >
  </b-card>
</template>
<script>
import UsersService from "@/services/UsersService";

export default {
  name: "EditProfileForm",
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      userLocal: {},
    };
  },
  mounted() {
    this.userLocal = JSON.parse(JSON.stringify(this.user));
    console.log(this.userLocal);
    console.log(this.user);
    this.$watch("user", () => {
      this.userLocal = JSON.parse(JSON.stringify(this.user));
    });
  },
  methods: {
    updateUser() {
      UsersService.updateUser({
        id: this.userLocal.id ? this.userLocal.id : "",
        firstName: this.userLocal.firstName ? this.userLocal.firstName : "",
        lastName: this.userLocal.lastName ? this.userLocal.lastName : "",
        email: this.userLocal.email ? this.userLocal.email : "",
      })
        .then((data) => {
          this.$notify({
            type: "success",
            text: data.msg,
          });
          this.$emit("update");
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            text: err.response.data.msg,
          });
        });
    },
    reset() {
      this.$emit("reset");
    },
  },
};
</script>
<style scoped>
.card {
  background-color: transparent !important;
  color: black;
}
</style>
