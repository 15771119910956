<template>
  <div>
    <b-container>
      <b-row v-for="(notif, key) in notifications" :key="key">
        <b-col>
          <b-alert show :variant="notif.type">
            <h4 class="alert-heading">
              <b-badge v-if="!notif.seen">New</b-badge> {{ notif.title }}
            </h4>
            <p>
              {{ notif.text }}
            </p>
            <hr />
            <p class="mb-0" style="font-size: 11px; margin-top: -10px">
              {{ new Date(notif.createdAt) }}
            </p>
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import NotificationService from "@/services/NotificationService";
export default {
  name: "NotificationsView",
  data() {
    return {
      notifications: [],
    };
  },
  mounted() {
    this.getData();
    NotificationService.setSeen();
  },
  methods: {
    async getData() {
      var data = await NotificationService.getNotifications().catch((err) => {
        this.$notify({
          type: "error",
          text: err.response.data.msg,
        });
        this.$router.push("/dashboard");
      });
      this.notifications = data.notifications.reverse();
    },
  },
};
</script>
<style lang="scss" scoped>
.image {
  max-width: 100%;
  max-height: 300px;
}
</style>
