import Vue from "vue";
import "./plugins/axios";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import Notifications from "vue-notification";
import VueGoodTablePlugin from "vue-good-table";
import CKEditor from "@ckeditor/ckeditor5-vue";

import "vue-good-table/dist/vue-good-table.css";
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Notifications);
Vue.use(CKEditor);
Vue.use(VueGoodTablePlugin);
new Vue({
  router,
  store,
  created: function () {},
  render: (h) => h(App),
}).$mount("#app");
