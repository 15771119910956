var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hello"},[_c('b-navbar',{style:(_vm.color == 'transparent'
        ? {
            paddingTop: 'calc(20px + 4vw)',
            paddingRight: 'calc(20px + 8vw)',
            paddingLeft: 'calc(20px + 8vw)',
            paddingBottom: '30px',
            transition: 'all 100ms',
          }
        : {
            paddingTop: '20px',
            paddingRight: '30px',
            paddingLeft: '30px',
            paddingBottom: '20px',
            transition: 'all 100ms',
          }),attrs:{"toggleable":"lg","type":_vm.color == 'transparent' ? 'dark' : 'light',"variant":_vm.color}},[_c('b-navbar-brand',{attrs:{"href":"/"}},[_vm._v(" Zelena Energija")]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{attrs:{"href":"/#home"}},[_vm._v("Početna")]),_c('b-nav-item',{attrs:{"href":"/#services"}},[_vm._v("Usluge")]),_c('b-nav-item',{attrs:{"href":"/#references"}},[_vm._v("Reference")]),_c('b-nav-item',{attrs:{"href":"/#contact"}},[_vm._v("Kontakt")]),_c('b-nav-item',{attrs:{"href":"/posts"}},[_vm._v("Novosti")]),(_vm.isLoggedIn)?_c('b-nav-item-dropdown',{attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('em',[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName))])]},proxy:true}],null,false,2834497696)},[_c('b-dropdown-item',{attrs:{"href":"/admin"}},[_vm._v("Pregled")]),_c('b-dropdown-item',{on:{"click":_vm.logout}},[_vm._v("Odjavi se")])],1):_vm._e(),_c('b-button',{attrs:{"variant":"success","href":"/subvencije"}},[_vm._v("Subvencije")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }