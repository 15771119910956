<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="tableData"
      theme="polar-bear"
      :pagination-options="{
        enabled: true,
        perPage: 5,
        position: 'bottom',
        perPageDropdown: [3, 7, 9],
        dropdownAllowAll: false,
        jumpFirstOrLast: true,
        firstLabel: 'First',
        lastLabel: 'Last',
        nextLabel: 'Next',
        prevLabel: 'Prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
      }"
      :search-options="{ enabled: true }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'">
          <b-button
            v-if="hasModifyPermission"
            variant="success"
            size="sm"
            style="margin-right: 5px"
            @click="
              () => {
                openModal(props.row.id, 'modify');
              }
            "
            icon
          >
            <b-icon icon="gear"></b-icon>
          </b-button>
          <b-button
            v-if="hasDeletePermission"
            variant="danger"
            size="sm"
            @click="
              () => {
                openModal(props.row.id, 'delete');
              }
            "
            icon
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
        </span>
        <span v-else-if="props.column.field == 'roles'">
          {{ props.formattedRow[props.column.field].join(", ") }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <b-modal
      v-model="manageRoleModal"
      :title="'Modify ' + selectedUser.email"
      header-text-variant="dark"
      header-bg-variant="light"
      body-bg-variant="light"
      footer-bg-variant="light"
    >
      <div>
        <div class="row">
          <div class="col-12">
            <div>
              <edit-profile-form
                :user="selectedUser"
                @update="getData"
                @reset="resetDetails"
              ></edit-profile-form>
              <edit-roles-form
                v-if="hasEditRolesPermission"
                v-bind:user="selectedUser"
                v-bind:roles="roles"
                @update="getData"
                @reset="resetRoles"
              ></edit-roles-form>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="manageRoleModal = false"
          >Close</b-button
        >
      </template>
    </b-modal>
    <b-modal
      v-model="deleteRoleModal"
      :title="'Delete ' + selectedUser.email"
      header-text-variant="dark"
      header-bg-variant="light"
      body-text-variant="dark"
      body-bg-variant="light"
      footer-text-variant="dark"
      footer-bg-variant="light"
    >
      <div>
        Are you sure you want to delete User <b>{{ selectedUser.email }}</b
        >?
      </div>
      <template #modal-footer>
        <b-button variant="secondary" @click="deleteRoleModal = false"
          >Cancel</b-button
        >
        <b-button
          variant="danger"
          @click="
            () => {
              removeUser(selectedUser.id);
            }
          "
          >Delete</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService.js";
import UsersService from "@/services/UsersService";
import RolesService from "@/services/RolesService";

import EditRolesForm from "../../Forms/EditRolesForm";
import EditProfileForm from "../../Forms/EditProfileForm";
export default {
  name: "UsersView",
  components: {
    EditRolesForm,
    EditProfileForm,
  },
  data() {
    let expandedRows = [];
    return {
      dataTable: null,
      tableData: [],
      columns: [
        { label: "#", field: "id" },
        { label: "First Name", field: "firstName" },
        { label: "LastName", field: "lastName" },
        { label: "Email", field: "email" },
        { label: "Roles", field: "roles" },
      ],
      roles: [],
      hasEditRolesPermission: false,
      hasDeletePermission: false,
      hasModifyPermission: false,
      manageRoleModal: false,
      deleteRoleModal: false,
      selectedUser: {},
      expandedRows,
    };
  },
  async mounted() {
    let user = await AuthService.userDetails();
    this.hasEditRolesPermission =
      this.checkPermission(user.permissions, "admin.roles") &&
      this.checkPermission(user.permissions, "users.manage");
    this.hasModifyPermission = this.checkPermission(
      user.permissions,
      "users.manage"
    );
    this.hasDeletePermission = this.checkPermission(
      user.permissions,
      "users.remove"
    );
    if (this.hasModifyPermission || this.hasDeletePermission) {
      this.columns.push({ label: "Actions", field: "actions" });
    }
    this.getData();
  },
  methods: {
    toggleRow(props) {
      var id = this.expandedRows.findIndex((el) => el == props.row.id);
      if (id == -1) {
        this.expandedRows = [props.row.id];
      } else {
        this.expandedRows = [];
      }
    },
    openModal(id, action) {
      let user = this.tableData.find((a) => a.id == id);
      if (action == "modify") {
        this.selectedUser = user;
        this.manageRoleModal = !this.manageRoleModal;
      } else {
        this.selectedUser = user;
        this.deleteRoleModal = !this.deleteRoleModal;
      }
      console.log(this.selectedUser);
    },
    removeUser() {
      this.deleteRoleModal = !this.deleteRoleModal;
      UsersService.removeUser({
        id: this.selectedUser.id,
      })
        .then((data) => {
          this.$notify({
            title: "SUCCESS",
            type: "success",
            text: data.msg,
          });
          this.getData();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "ERROR",
            type: "danger",
            text: err.response.data.msg,
          });
        });
    },
    async getData() {
      this.$emit("loading");
      let data = await UsersService.getUsers().catch((err) => {
        console.log(err);
        this.$notify({
          title: "ERROR",
          type: "danger",
          text: "You do not have permissions to view users!",
        });
        data = [];
      });
      if (this.hasEditRolesPermission) {
        let roles = await RolesService.getRoles();
        this.roles = roles.roles;
      }
      let users = data.users;
      this.tableData = [];
      for (var i = 0; i < users.length; i++) {
        let user = users[i];
        if (this.selectedUser.id == user.id) {
          this.selectedUser = user;
        }
        this.tableData.push(user);
      }
    },
    checkPermission(permissions, permission) {
      if (permissions.includes("*") || permissions.includes("*.*")) {
        return true;
      }
      if (permissions.includes(permission)) {
        return true;
      }
      if (permission.includes(".")) {
        let strings = permission.split(".");
        if (permissions.includes(strings[0] + ".*")) {
          return true;
        }
      }
      return false;
    },
    async resetRoles() {
      let data = await UsersService.getUsers().catch((err) => {
        console.log(err);
        this.$notify({
          title: "ERROR",
          type: "danger",
          text: "You do not have permissions to view users!",
        });
        data = [];
      });
      let user = data.users.find((s) => s.id == this.selectedUser.id);
      this.selectedUser.roles = user.roles;
    },
    async resetDetails() {
      let data = await UsersService.getUsers().catch((err) => {
        console.log(err);
        this.$notify({
          title: "ERROR",
          type: "danger",
          text: "You do not have permissions to view users!",
        });
        data = [];
      });
      let user = data.users.find((s) => s.id == this.selectedUser.id);
      user.roles = this.selectedUser.roles;
      this.selectedUser = user;
    },
  },
};
</script>
<style type="scss"></style>
