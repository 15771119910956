import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=584b0880&scoped=true"
import script from "./Form.vue?vue&type=script&lang=js"
export * from "./Form.vue?vue&type=script&lang=js"
import style1 from "./Form.vue?vue&type=style&index=1&id=584b0880&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "584b0880",
  null
  
)

export default component.exports