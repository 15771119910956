<template>
  <div>
    <div
      :class="{ loading: true, 'loading-active': loaderVisible }"
      v-if="loaderActive"
    >
      <img width="350" src="@/assets/ZE_Loading.gif" />
    </div>
    <NavBar
      style="z-index: 1500; position: fixed; left: 0px; right: 0px"
      :color="scrollPosition > 20 ? 'white' : 'transparent'"
    ></NavBar>
    <div class="landing" id="home">
      <div class="landing-background">
        <div class="landing-background-color"></div>
      </div>
      <div class="landing-text row">
        <div class="landing-logo"></div>
        <div class="landing-header">Zelena Energija | Subvencije</div>
        <div class="landing-subheader">
          Iskoristite potencijal sunca na najbolji nacin!
        </div>
      </div>
    </div>
    <div class="form">
      <div style="overflow-x: hidden" class="form-container">
        <div class="about row justify-content-center">
          <div class="contact" id="contact">
            <div class="tab-header text-center">
              <h1 style="color: black">Prijavite se za Subvencije</h1>
              <div style="color: #115d11 !important" class="line">_______</div>
              <h6 style="color: #115d11 !important; text-transform: none">
                U daljem kontekstu ispunite formular za prijavu u program
                Subvencija!
                <br />
                <br />
                <a style="font-size: 12px"
                  >* U
                  <a href="/posts" class="news-link">Novostima</a>
                  možete pronaći značajne informacije o Subvencijama!</a
                >
              </h6>
            </div>
            <div class="row contact-form mx-auto justify-center">
              <div class="col mx-auto justify-center">
                <b-form-group class="mx-auto" label="Ime i Prezime:">
                  <b-form-input
                    v-model="form.name"
                    placeholder="Ime Prezime"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col mx-auto justify-center">
                <b-form-group
                  id="input-group-3"
                  label="Fizičko/Pravno Lice:"
                  label-for="input-3"
                >
                  <b-form-select
                    v-model="form.personType"
                    :options="[
                      { text: 'Fizičko Lice', value: 1 },
                      { text: 'Pravno Lice', value: 2 },
                    ]"
                    required
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>
            <div class="row contact-form mx-auto justify-center">
              <div class="col mx-auto justify-center">
                <b-form-group class="mx-auto" label="Broj Telefona:">
                  <b-form-input
                    v-model="form.phone"
                    placeholder="Broj Telefona"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col mx-auto justify-center">
                <b-form-group class="mx-auto" label="Email:">
                  <b-form-input
                    v-model="form.email"
                    placeholder="Email"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row contact-form mx-auto justify-center">
              <div class="col mx-auto justify-center">
                <b-form-group class="mx-auto" label="Opština:">
                  <b-form-input
                    v-model="form.city"
                    placeholder="Opština"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col mx-auto justify-center">
                <b-form-group class="mx-auto" label="Mesto/Adresa:">
                  <b-form-input
                    v-model="form.address"
                    placeholder="Mesto/Adresa"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row contact-form mx-auto justify-center">
              <div class="col mx-auto justify-center">
                <b-form-group label="Vrsta Kuće:" label-for="input-3">
                  <b-form-select
                    v-model="form.houseType"
                    :options="[
                      { text: 'Prizemna', value: 1 },
                      { text: 'Spratna', value: 2 },
                    ]"
                    required
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col mx-auto justify-center">
                <b-form-group class="mx-auto" label="Vrsta Krova:">
                  <b-form-input
                    v-model="form.roofType"
                    placeholder="Crep, Lim, ..."
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row contact-form mx-auto justify-center">
              <div class="col mx-auto justify-center">
                <b-form-group
                  class="mx-auto"
                  label="Prosečna mesečna potrošnja struje:"
                >
                  <b-form-input
                    v-model="form.powerCons"
                    placeholder="Vrednost u kWh"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col mx-auto justify-center">
                <b-form-group label="Tip Struje:" label-for="input-3">
                  <b-form-select
                    v-model="form.powerType"
                    :options="[
                      { text: 'Monofazna', value: 1 },
                      { text: 'Trofazna', value: 2 },
                      { text: 'Off-Grid', value: 3 },
                    ]"
                    required
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>
            <div
              v-if="form.error"
              class="row contact-form error-msg mx-auto justify-center mb-3"
            >
              <div class="col text-center">
                <a class="">{{ form.error }}</a>
              </div>
            </div>
            <div
              v-if="form.success"
              class="row contact-form success-msg mx-auto justify-center mb-3"
            >
              <div class="col text-center">
                <a class="">{{ form.success }}</a>
              </div>
            </div>
            <div class="row contact-form mx-auto justify-center">
              <div class="col mx-auto justify-center text-center">
                <b-button
                  size="lg"
                  style="
                    font-size: 14px;
                    margin-top: 15px;
                    width: 150px;
                    height: 60px;
                  "
                  variant="success"
                  @click="sendForm()"
                  >Podnesi Prijavu!</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer">
        <b-container>
          <b-row>
            <b-col cols="12" md="6"
              ><iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2782.985990431689!2d19.10357317676359!3d45.771471012883836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475cb58a5533337f%3A0xf0589c48c5479961!2z0JzQsNGA0LjRmNC1INCR0YPRgNGB0LDRmyAyMywg0KHQvtC80LHQvtGA!5e0!3m2!1ssr!2srs!4v1719520736795!5m2!1ssr!2srs"
                width="100%"
                height="300"
                style="
                  border: 0;
                  border-radius: 10px;
                  filter: invert(1) hue-rotate(196deg);
                "
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </b-col>
            <b-col cols="12" md="6" class="contactf">
              <h2>Kontakt</h2>
              <b-row>
                <b-col cols="12" style="margin-bottom: 10px">
                  <a style="font-size: 20px" href="tel:+381600285701">
                    <b-icon
                      style="font-size: 25px; margin-top: -3px"
                      icon="phone"
                    ></b-icon
                    >+381 60 028 5701</a
                  >
                </b-col>
                <b-col cols="12" style="margin-bottom: 10px">
                  <a
                    style="font-size: 20px"
                    href="mailto:zzelenaenergija@gmail.com"
                  >
                    <b-icon
                      style="font-size: 25px; margin-top: -3px"
                      icon="envelope-open"
                    ></b-icon>
                    zzelenaenergija@gmail.com</a
                  >
                </b-col>
                <b-col cols="12" style="margin-bottom: 10px">
                  <a
                    style="font-size: 20px"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/zzelenaenergijars"
                  >
                    <b-icon
                      style="font-size: 25px; margin-top: -3px"
                      icon="facebook"
                    ></b-icon>
                    Zelena Energija
                  </a>
                </b-col>
                <b-col cols="12">
                  <a
                    href="https://www.instagram.com/zelena_energija_srbija/"
                    style="font-size: 20px"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <b-icon
                      style="font-size: 25px; margin-top: -3px"
                      icon="instagram"
                    ></b-icon>
                    zelenaenergija</a
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col style="margin-top: 20px"
              ><h6>Copyright © Zelena Energija 2024</h6></b-col
            >
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import SubmissionsService from "@/services/SubmissionsService";
export default {
  name: "FormView",
  data() {
    return {
      scrollPosition: null,
      form: {
        name: "",
        phone: "",
        email: "",
        roofType: "",
        city: "",
        address: "",
        personType: -1,
        houseType: -1,
        powerType: -1,
      },
      loaderActive: false,
      loaderVisible: false,
    };
  },
  components: {
    NavBar,
  },
  async beforeCreate() {},
  async created() {},
  async mounted() {
    window.addEventListener("scroll", this.updateScroll);
    this.getData();
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    sendForm() {
      this.loaderActive = true;
      this.loaderVisible = true;
      if (
        this.form.name == "" ||
        this.form.phone == "" ||
        this.form.email == "" ||
        this.form.roofType == "" ||
        this.form.city == "" ||
        this.form.address == "" ||
        this.form.personType == -1 ||
        this.form.houseType == -1 ||
        this.form.powerType == -1
      ) {
        this.form = {
          error: "Molimo Vas da popunite sva gore navedena polja!",
        };
      } else
        SubmissionsService.sendForm(this.form)
          .then(() => {
            this.form = {
              success:
                "Uspešno ste poslali prijavu za Subvenciju! Očekujte odgovor na EMail-u od ekipe Zelene Energije!",
            };
          })
          .catch(() => {
            this.form = {
              error:
                "Greška prilikom slanja prijave za Subvenciju, pokušajte ponovo kasnije!",
            };
          });
      setTimeout(() => {
        this.loaderVisible = false;
        setTimeout(() => {
          this.loaderActive = false;
        }, 250);
      }, 1000);
    },
  },
};
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.contact {
  width: 80vw;
  .contact-form {
    max-width: 800px;
  }
}
.news-link {
  color: green;
  border: 1px solid green;
  border-radius: 5px;
  text-decoration: none;
  transition: all 100ms ease-in-out;
  &:hover {
    text-decoration: none;
    background-color: green;
    color: white;
  }
}
.footer {
  background-color: rgb(20, 20, 20);
  height: auto;
  padding-top: 50px;
  padding-bottom: 10px;
  color: white;
  width: 100%;
  background-color: black;
  color: rgb(129, 139, 130);
  padding: 50px;
  z-index: 1000;
}
.footer {
  .contactf {
    * {
      text-align: left;
    }
    h6 {
      text-transform: uppercase;
      font-weight: bold;
      color: rgb(82, 82, 82);
    }
    a {
      color: rgb(167, 167, 167);
    }
  }
}
.form {
  position: absolute;
  top: 90vh;
  left: 0px;
  right: 0px;
  background-color: white;
}
.form-container {
  margin-bottom: 100px;
  margin-top: -150px;
}
.btn-home {
  position: absolute;
  top: 5px;
  left: 15px;
}
.btn-add {
  position: absolute;
  top: 5px;
  right: 15px;
}
.ticket {
  font-size: 0.875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    transform 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.has-success:after,
.has-danger:after {
  font-family: "nucleo";
  content: "\EA1B";
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 10px;
  color: #00f2c3;
  font-size: 11px;
}
.bg-primary {
  position: absolute !important;
}
.bg-rotate:after {
  content: "";
  position: absolute;
  top: 80%;
  left: 0;
  height: 100%;
  width: 150%;
  background: #1e1e2f !important;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  transform: rotate(-5deg);
}
.about {
}
.landing {
  height: 80vh;
  .landing-background,
  .landing-background-color {
    position: fixed;
    left: -1vw;
    top: -1vh;
    width: 102vw;
    height: 102vh;
    background-color: #115d33;
    background-image: linear-gradient(60deg, #115d33, #228b22);
    background-position: 100% 10%;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(4px);
  }
  .landing-background {
    background-image: url("@/assets/img/DJI_0313.JPG");
  }
  .landing-background-color {
    opacity: 0.7;
    top: 0;
  }
  .landing-text {
    color: white;
    position: absolute;
    top: 20vh;
    left: 15vw;
    width: calc(130px + 40vw);
    height: calc(40px + 8vw);
    padding: 20px;
    border-left: 2px solid white;
    text-wrap: nowrap;
    .landing-logo {
      background-image: url("@/assets/logo.png");
      background-position: 0% 0%;
      background-size: contain;
      background-repeat: no-repeat;
      height: calc(10px + 7vw);
      width: calc(10px + 7vw);
      position: absolute;
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222) hue-rotate(30deg);
    }
    .landing-header {
      margin-left: calc(20px + 7vw);
      margin-top: -10px;
      color: white;
      font-weight: bold;
      text-align: left;
      font-size: calc(4vw + 10px);
      filter: drop-shadow(2px 2px 5px #222);
    }
    .landing-subheader {
      font-size: calc(0.2vw + 10px);
      text-align: left;
      margin-left: calc(20px + 7vw);
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222);
    }
  }
}
</style>
